import React, { useEffect } from 'react';
import { RootState } from 'redux/types';
import { IntlShape } from 'react-intl';
import Autocomplete from 'components/Autocomplete';
import Input from 'components/Input/Input';
import { Place, placeSelectors } from 'module/Place';
import { useGetPlaces } from 'module/Place/hooks';
import { useSelector } from 'react-redux';
import { FormFields } from './StaffingForm.form';
import { StaffingFormValues } from '.';

interface Props {
  intl: IntlShape;
  setFieldValue: Function;
  values: Record<FormFields, string | number | boolean | any[]> & StaffingFormValues;
  isEditionDisabled?: boolean;
}

const FormPlace: React.FunctionComponent<Props> = ({
  intl,
  setFieldValue,
  values,
  isEditionDisabled,
}) => {
  const [getPlaceData, getPlace] = useGetPlaces();

  const places = useSelector(placeSelectors.selectAll);
  const selectedPlaceId = values && values.place;
  const selectedPlace = useSelector((state: RootState) =>
    placeSelectors.selectById(state, selectedPlaceId),
  );

  const SelectedPlaceName = selectedPlace ? selectedPlace.name : values.place;

  const getValueFromItem = (placeName: string) => {
    const placeValue = places.filter((place: Place) => place.name === placeName)[0];
    return placeValue ? placeValue.name : placeName;
  };

  useEffect(() => {
    Promise.all([getPlace()]);
  }, []);

  return getPlaceData.loading ? (
    <Input
      name={FormFields.place}
      label="staffingForm.place"
      placeholder={intl.formatMessage({ id: 'staffingForm.place-placeholder' })}
      disabled={true}
    />
  ) : (
    <Autocomplete
      label="staffingForm.place"
      placeholder={intl.formatMessage({ id: 'staffingForm.place-placeholder' })}
      name="place"
      items={places.filter(place => place.name !== undefined)}
      getItemValue={(place: Place) => place.name}
      value={SelectedPlaceName}
      setFieldValue={setFieldValue}
      getValueFromItem={getValueFromItem}
      inputProps={{ disabled: isEditionDisabled }}
    />
  );
};

export default FormPlace;
