import { SnackBarType } from 'module/SnackBars';
import styled from 'styled-components';
import { colorPalette } from 'stylesheet';

const colors = {
  [SnackBarType.ERROR]: colorPalette.red,
};

export const SnackBarContainer = styled.div<{ type: SnackBarType }>`
  background-color: ${props => colors[props.type]};
  margin-top: 10px;

  padding: 14px 16px;
  box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%);
  border-radius: 4px;

  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

export const SnackBarText = styled.p`
  flex-grow: 1;
  color: ${colorPalette.greyLight};
`;
