import styled from 'styled-components';

export const StaffingFormLink = styled.a`
  color: black;
  text-decoration: none;
  width: 100%;

  label {
    cursor: pointer;
  }

  input {
    pointer-events: none;
  }
`;
