import AppCrashFallback from 'components/AppCrashFallback';
import CSSResets from 'components/CSSResets';
import ErrorBoundary from 'components/ErrorBoundary';
import Root from 'components/Root';
import { NextPageContext } from 'next';
import withRedux, { ReduxWrapperAppProps } from 'next-redux-wrapper';
import App, { AppContext } from 'next/app';
import React from 'react';
import { Provider } from 'react-redux';
import configureStore from 'redux/store';
import { captureException } from 'services/sentry';
import 'react-datepicker/dist/react-datepicker.css';

interface Props extends ReduxWrapperAppProps {
  hasError: boolean;
  errorEventId: string;
}

interface NextReduxContext extends NextPageContext, Props {}

interface NextReduxAppContext extends AppContext {
  ctx: NextReduxContext;
}

// @ts-ignore Could not find solution to this one
class MyApp extends App<Props> {
  static async getInitialProps(props: NextReduxAppContext) {
    const { Component, ctx } = props;

    //TODO add lautomatic login logic here

    try {
      const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};
      return { pageProps };
    } catch (error) {
      // Capture errors that happen during a page's getInitialProps.
      // This will work on both client and server sides.
      const errorEventId = captureException(error, ctx);
      return {
        hasError: true,
        errorEventId,
        pageProps: {},
      };
    }
  }

  render() {
    const { Component, pageProps, store, hasError, errorEventId } = this.props;
    return (
      <ErrorBoundary
        FallbackComponent={AppCrashFallback}
        hasError={hasError}
        eventId={errorEventId}
      >
        <Provider store={store}>
          <CSSResets />
          <Root>
            <Component {...pageProps} />
          </Root>
        </Provider>
      </ErrorBoundary>
    );
  }
}

export default withRedux(configureStore)(MyApp);
