import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniqueId } from 'lodash';
import { SnackBarType } from './types';

export type SnackBarState = {
  id: string;
  type: SnackBarType;
  text: string;
};

export type SnackBarsState = SnackBarState[];

const SnackBarsSlice = createSlice({
  name: 'SnackBars',
  initialState: [] as SnackBarsState,
  reducers: {
    pushErrorSnackBar: (state, action: PayloadAction<string>) => {
      return [
        ...state,
        {
          id: uniqueId(),
          type: SnackBarType.ERROR,
          text: action.payload,
        },
      ];
    },
    removeSnackBar: (state, action: PayloadAction<string>) =>
      state.filter(snackBar => snackBar.id !== action.payload),
  },
});

export const { pushErrorSnackBar, removeSnackBar } = SnackBarsSlice.actions;

export default SnackBarsSlice.reducer;
