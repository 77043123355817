import { FabProps, SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import React from 'react';
import { FabButtonContainer } from './FabButton.style';

type FabButtonProps = {
  onClick: () => void;
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
} & Omit<FabProps, 'children' | 'className' | 'size' | 'color'>;

const FabButton: React.FunctionComponent<FabButtonProps> = ({ onClick, Icon, ...props }) => (
  <FabButtonContainer onClick={onClick} {...props} className={'fab'} size="medium" color="primary">
    <Icon />
  </FabButtonContainer>
);

export default FabButton;
