import html2canvas from 'html2canvas';
import { getEndDate, getStartDate } from 'module/Filter';
import { updateExporting } from 'module/Planning';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionButton, ActionIcon, DownloadButton } from './ExportButton.style';

const ExportButton: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  const [isGenerating, setIsGenerating] = useState(false);

  const startDate = useSelector(getStartDate);
  const endDate = useSelector(getEndDate);

  const generateExport = () => {
    setIsGenerating(true);
    dispatch(updateExporting(true));

    const button = document.getElementById('download-export') as HTMLLinkElement;
    const GridContainer = document.getElementById('GridContainer') as HTMLElement;
    const GridColumns = document.getElementById('GridColumns');

    if (GridColumns === null) {
      return;
    } else {
      const width = GridColumns.scrollWidth + 100;
      const height = GridColumns.scrollHeight + 130;

      html2canvas(GridContainer, {
        width,
        height,
        windowWidth: width,
        windowHeight: height,
      }).then(canvas => {
        const image = canvas.toDataURL('image/jpeg', 1);
        button.href = image;
        button.click();
        setIsGenerating(false);
        dispatch(updateExporting(false));
      });
    }
  };

  const formatDate = (date: Date) => moment(new Date(date)).format('DD-MM-YYYY');

  return (
    <>
      <ActionButton disabled={isGenerating} onClick={() => generateExport()}>
        <ActionIcon />
      </ActionButton>
      <DownloadButton
        id="download-export"
        download={`Cubik Staffing - ${formatDate(startDate)} - ${formatDate(endDate)}.jpg`}
      ></DownloadButton>
    </>
  );
};

export default ExportButton;
