import { Planning } from 'module/Planning';
import { FormikBag } from 'formik';
import { isUndefined } from 'lodash';
import { Fee } from 'module/Fee';
import { NEW_PLANNING_ID } from 'module/Planning/utils';
import {
  INVOICED_FREELANCE_INPUT_VALUES,
  INVOICED_INPUT_VALUES,
} from './StaffingForm.invoicing.form';
import { INTERVENTION_TYPE_INPUT_VALUES } from './StaffingForm.training.form';

export interface StaffingFormValues {
  project: string;
  personalConstraints: string;
  hasFees: string;
  interventionType: string;
  invoiced?: string;
  invoicedFreelance?: string;
  details: string;
  brief: string;
  dayNumber: number;
  place: string;
  specificMaterial: string;
  workAtHome: boolean;
  consultantConfirmation: boolean;
  consultantValidation: boolean;
  feeList: Fee[];
  isReservation: boolean;
  isConflict: boolean;
  conflictingProjectId: string;
  seller: string;
  mealVoucher: string;
  isNew: boolean;
}

interface StaffingFormServiceStateProps {
  staffingFormError?: string | null;
  loading: boolean;
  isEditionDisabled?: boolean;
  planning: Planning;
  fees: Fee[];
}

interface StaffingFormServiceDispatchProps {
  submit: (values: StaffingFormValues) => void;
}

export type StaffingFormServiceProps = StaffingFormServiceStateProps &
  StaffingFormServiceDispatchProps;

const mapInvoicedToFieldValue = (
  invoiced: Planning['invoiced'],
  companionship: Planning['companionship'],
  invoicedCompanionship: Planning['invoicedCompanionship'],
): StaffingFormValues['invoiced'] => {
  if (isUndefined(invoiced)) return undefined;
  if (invoiced) return INVOICED_INPUT_VALUES.YES;
  if (companionship && invoicedCompanionship) return INVOICED_INPUT_VALUES.INVOICED_COMPANIONSHIP;
  if (companionship) return INVOICED_INPUT_VALUES.COMPANIONSHIP;
  return INVOICED_INPUT_VALUES.NO;
};

const mapInvoicedFreelanceToFieldValue = (
  invoicedFreelance: Planning['invoicedFreelance'],
): StaffingFormValues['invoicedFreelance'] => {
  if (isUndefined(invoicedFreelance)) return undefined;
  if (invoicedFreelance) return INVOICED_FREELANCE_INPUT_VALUES.YES;
  return INVOICED_FREELANCE_INPUT_VALUES.NO;
};

export const mapPropsToValues = ({
  planning: {
    projectId,
    personalConstraints,
    hasFees,
    interventionType,
    invoiced,
    invoicedFreelance,
    details,
    briefLink,
    dayNumber,
    place,
    specificMaterial,
    companionship,
    invoicedCompanionship,
    workAtHome,
    consultantConfirmation,
    consultantValidation,
    isReservation,
    isConflict,
    conflictingProjectId,
    seller,
    mealVoucher,
    id,
  },
  fees,
}: StaffingFormServiceProps): StaffingFormValues => {
  return {
    project: projectId ?? '',
    personalConstraints: personalConstraints ?? '',
    hasFees: hasFees ?? '',
    interventionType: interventionType ?? INTERVENTION_TYPE_INPUT_VALUES.ANIMATION,
    invoiced: mapInvoicedToFieldValue(invoiced, companionship, invoicedCompanionship),
    invoicedFreelance: mapInvoicedFreelanceToFieldValue(invoicedFreelance),
    details: details ?? '',
    brief: briefLink ?? '',
    dayNumber: dayNumber ?? 0,
    place: place ?? '',
    specificMaterial: specificMaterial ?? '',
    workAtHome: workAtHome ?? false,
    consultantConfirmation: consultantConfirmation ?? false,
    consultantValidation: consultantValidation ?? false,
    feeList: fees ?? [],
    isReservation: isReservation ?? false,
    isConflict: isConflict ?? false,
    conflictingProjectId: conflictingProjectId ?? '',
    seller: seller ?? '',
    mealVoucher: mealVoucher ?? '',
    isNew: !id || id === NEW_PLANNING_ID,
  };
};

export const mapValuesToPlanning = (
  values: StaffingFormValues,
): Omit<Planning, 'id' | 'isAM' | 'isMerged' | 'peopleId' | 'staffingDate' | 'feeList'> => ({
  projectId: values.project,
  personalConstraints: values.personalConstraints,
  companionship:
    values.invoiced === INVOICED_INPUT_VALUES.COMPANIONSHIP ||
    values.invoiced === INVOICED_INPUT_VALUES.INVOICED_COMPANIONSHIP,
  invoiced: values.invoiced === INVOICED_INPUT_VALUES.YES,
  invoicedFreelance: values.invoicedFreelance === INVOICED_FREELANCE_INPUT_VALUES.YES,
  invoicedCompanionship: values.invoiced === INVOICED_INPUT_VALUES.INVOICED_COMPANIONSHIP,
  hasFees: values.hasFees,
  interventionType: values.interventionType,
  details: values.details,
  dayNumber: values.dayNumber,
  place: values.place,
  specificMaterial: values.specificMaterial,
  workAtHome: values.workAtHome,
  consultantConfirmation: values.consultantConfirmation,
  consultantValidation: values.consultantValidation,
  isReservation: values.isReservation,
  isConflict: values.isConflict,
  conflictingProjectId: values.conflictingProjectId,
  mealVoucher: values.mealVoucher,
});

export const handleSubmit = (
  values: StaffingFormValues,
  { props }: FormikBag<StaffingFormServiceProps, StaffingFormValues>,
): void => {
  props.submit(values);
};
