import { getEndDate, getStartDate } from 'module/Filter';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useIntl } from 'react-intl';

import client, { DATA_ENDPOINT } from 'services/networking/client';
import { getUserId } from 'module/Login';
import { useDisplayErrorSnackBar } from 'module/SnackBars';
import { ActionButton, ActionIcon } from './ExportCostsButton.style';

const ExportCostsButton: React.FunctionComponent = () => {
  const startDate = useSelector(getStartDate);
  const endDate = useSelector(getEndDate);
  const currentUserId = useSelector(getUserId);
  const [downloading, setDownloading] = useState(false);
  const displayError = useDisplayErrorSnackBar();
  const intl = useIntl();

  const downloadCosts = useCallback(async () => {
    setDownloading(true);

    const formattedStartDateForFormula = moment(startDate).subtract(1, 'days').format('YYYY-MM-DD');
    const formattedEndDateForFormula = moment(endDate).add(1, 'days').format('YYYY-MM-DD');

    if (!currentUserId) return;

    //, {Cubik}="${currentUserId}"
    const formula = `?filterByFormula=AND(IS_AFTER({Date de staffing},'${formattedStartDateForFormula}'), IS_BEFORE({Date de staffing},'${formattedEndDateForFormula}'), FIND('${currentUserId}', {ID Cubik*}))`;

    const fees = await client.get(DATA_ENDPOINT + 'costs' + formula);

    if (fees) {
      const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
      const formattedEndDate = moment(endDate).format('YYYY-MM-DD');

      // We add \ufeff to generate the corrent BOM marker.
      // https://stackoverflow.com/questions/17879198/adding-utf-8-bom-to-string-blob/27975629#27975629

      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      const url = window.URL.createObjectURL(new Blob(['\ufeff' + fees]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Frais ${formattedStartDate} - ${formattedEndDate}.csv`);

      document.body.appendChild(link);
      link.click();

      if (link.parentNode) link.parentNode.removeChild(link);
    } else {
      displayError(intl.formatMessage({ id: 'download.costs.empty' }));
    }

    setDownloading(false);
  }, [startDate, endDate, currentUserId]);

  return (
    <>
      <ActionButton disabled={downloading || !currentUserId} onClick={downloadCosts}>
        <ActionIcon />
      </ActionButton>
    </>
  );
};

export default ExportCostsButton;
