import styled from 'styled-components';
import { colorPalette, getSpacing, typography } from 'stylesheet';
import React from 'react';
import Autocomplete from 'react-autocomplete';
import { Project } from 'module/Project';
import { withRow } from 'components/InputsUtils';
import { StyledInput } from 'components/Input/Input';

const StyledItem = styled.div<{ isHighlighted: boolean }>`
  ${typography.main}
  display: flex;
  background-color: ${({ isHighlighted }) =>
    isHighlighted ? colorPalette.blueLight : colorPalette.white};
  padding: ${getSpacing(1)} ${getSpacing(3)};
`;

export type AutocompleteProps = {
  items: Project[];
  getItemValue: (item: Project) => string;
  value: string;
  setFieldValue: (name: string, value: string) => void;
  name: string;
  getValueFromItem: (value: string) => string;
  placeholder: string;
  reducedSize: boolean;
};

const StyledAutocomplete: React.FunctionComponent<AutocompleteProps> = ({
  getItemValue,
  name,
  setFieldValue,
  getValueFromItem,
  value,
  placeholder,
  ...props
}: AutocompleteProps) => {
  let newProps: any = {
    ...props,
  };
  if (props.reducedSize) {
    newProps = {
      ...props,
      wrapperStyle: { display: 'flex', flex: 1, position: 'relative' },
      menuStyle: {
        maxHeight: '130px',
        overflow: 'auto',
        position: 'absolute',
        left: 0,
        top: '60px',
      },
    };
  }
  return (
    <Autocomplete
      wrapperStyle={{ display: 'flex', flex: 1 }}
      renderInput={(inputProps: Record<string, any>) => (
        <StyledInput name={''} placeholder={placeholder} {...inputProps} />
      )}
      shouldItemRender={(item, val) =>
        val ? item.name.toLowerCase().includes(val.toLowerCase()) : true
      }
      getItemValue={getItemValue}
      renderItem={(item, isHighlighted) => (
        <div key={item.name}>
          <StyledItem isHighlighted={isHighlighted} key={item.name}>
            {item.name}
          </StyledItem>
        </div>
      )}
      value={value}
      onChange={e => setFieldValue(name, getValueFromItem(e.target.value))}
      onSelect={val => setFieldValue(name, getValueFromItem(val))}
      {...newProps}
    />
  );
};

export default withRow(StyledAutocomplete);
