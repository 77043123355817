import styled from 'styled-components';
import { borderRadius, colorPalette, getSpacing, typography } from 'stylesheet';
import { WrappedInputProps } from 'components/InputsUtils/types';
import React from 'react';

const getBorderColor = (originalColor: string, error?: string): string =>
  error ? colorPalette.red : originalColor;

const StyledTextarea = styled.textarea<WrappedInputProps>`
  ${typography.main}
  width: 100%;
  height: 60px;
  background-color: ${colorPalette.white};
  padding: 0 ${getSpacing(3)};
  border-radius: ${borderRadius.medium};
  border: 1px solid ${({ error }) => getBorderColor(colorPalette.grey, error)};
  -webkit-appearance: none;

  :hover {
    border-color: ${({ error }) => getBorderColor(colorPalette.greyDark, error)};
  }

  :focus {
    border-color: ${({ error }) => getBorderColor(colorPalette.blueDark, error)};
  }

  ::placeholder {
    color: ${colorPalette.grey};
  }
`;

StyledTextarea.displayName = 'StyledTextarea';

const TextareaComponent: React.FunctionComponent<WrappedInputProps> = (
  props: WrappedInputProps,
) => <StyledTextarea {...props} />;

export default TextareaComponent;
