import Button from 'components/Button';
import styled from 'styled-components';
import { colorPalette, getSpacing, size } from 'stylesheet';

export const FeesFormContainer = styled.div`
  width: 100%;
  overflow-x: scroll;
  margin-bottom: ${getSpacing(5)};

  & > * + * {
    margin-top: ${getSpacing(4)};
  }
`;

export const FeeLine = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;

  & > :first-child {
    flex: 0;
    margin: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0 0.75em;
  }
`;

export const FeeFormHeader = styled.div`
  background: ${colorPalette.feeLine};
  color: white;
  display: flex;
  padding: ${getSpacing(2)} ${getSpacing(4)};
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  cursor: pointer;
`;

export const FeeFormHeaderDesc = styled.div`
  flex-grow: 1;
  overflow: hidden;
  padding-right: ${getSpacing(2)};
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FeeFormHeaderPrice = styled.div`
  min-width: 60px;
`;

interface FeeLineFormProps {
  displayed?: boolean;
}

export const FeeLineForm = styled.div<FeeLineFormProps>`
  display: ${({ displayed }) => (displayed ? 'flex' : 'none')};
  flex-direction: column;
  padding: ${getSpacing(4)};
  width: 100%;

  @media (min-width: ${size.tablet}) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    & > * {
      width: 49%;
    }
  }
`;

interface FeeLineFormContainerProps {
  isActive?: boolean;
}

export const FeeLineFormContainer = styled.div<FeeLineFormContainerProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${({ isActive }) => (isActive ? colorPalette.greyLight : 'none')};
`;

export const FeeFormInputContainer = styled.div<{ small?: boolean }>`
  display: flex;
  ${({ small }) => (small ? `min-width: ${getSpacing(22)};` : `min-width: ${getSpacing(30)};`)}

  &:last-child {
    & > * {
      margin-bottom: 0;
    }
  }
`;

const backgroundColor = (disabled?: boolean) =>
  disabled ? colorPalette.greyLight : colorPalette.blueDark;

export const AddFeeButton = styled(Button)`
  background-color: ${({ disabled }) => backgroundColor(disabled)};
  color: ${props => (props.disabled ? colorPalette.white : colorPalette.greyLight)};
  margin-bottom: ${getSpacing(5)};
`;

export const DeleteFeeButton = styled(Button)`
  align-self: center;
  background: none;
  color: ${colorPalette.white};
  padding: ${getSpacing(2)};

  :hover {
    background: none;
    opacity: 0.7;
  }
`;
