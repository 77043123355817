import React from 'react';
import styled from 'styled-components';

type Props = {
  size?: number;
};

const DEFAULT_SIZE = 30;

export const StyledSVG = styled.svg<Props>`
  min-width: ${({ size }) => (size ? `${size}px` : `${DEFAULT_SIZE}px`)};
  min-height: ${({ size }) => (size ? `${size}px` : `${DEFAULT_SIZE}px`)};
`;

const FilterIcon: React.FunctionComponent<Props> = ({ size }) => {
  return (
    <StyledSVG
      size={size}
      enableBackground="new 0 0 410.182 410.182"
      version="1.1"
      viewBox="0 0 410.18 410.18"
    >
      <path d="m393 0h-375.81c-5.632 0-9.216 6.144-5.632 11.264l137.73 193.02c6.144 8.192 9.728 18.944 9.728 29.696v168.45c0.512 5.632 5.12 9.216 9.728 7.168l78.336-29.696c2.56-0.512 4.096-3.584 4.096-6.144v-139.78c0-10.752 3.584-20.992 9.728-29.696l137.73-193.02c3.584-5.12 0-11.264-5.632-11.264z" />
    </StyledSVG>
  );
};

export default FilterIcon;
