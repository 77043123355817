import React from 'react';
import styled from 'styled-components';
import { colorPalette, getSpacing, typography } from 'stylesheet';
import { FormattedMessage } from 'react-intl';

export const Label = styled.label<{ isCheckboxLabel?: boolean }>`
  display: block;
  flex-grow: 1;
  ${({ isCheckboxLabel }) => (!isCheckboxLabel ? typography.bold : ``)}
  ${({ isCheckboxLabel }) =>
    isCheckboxLabel ? `margin-left: ${getSpacing(1)};` : `margin-bottom: ${getSpacing(1)};`}
`;

export const Error = styled.p`
  color: ${colorPalette.red};
  ${typography.small}
  margin-top: ${getSpacing(1)};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: ${getSpacing(5)};
  justify-content: flex-end;
`;
export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const HintContainer = styled.div`
  ${typography.small}
  ${typography.light}
`;

const withRow = (WrappedComponent: React.FunctionComponent<any>): React.FunctionComponent<any> => {
  const WrappedComponentWithRow = (props: any) => {
    const { label, error, hints, type } = props;
    const hasError = !!error;
    const isCheckbox = type === 'checkbox';
    return (
      <Row>
        {!isCheckbox && label && (
          <Label isCheckboxLabel={false}>
            <FormattedMessage id={label} />
          </Label>
        )}
        {hints && hints.map((hint: string) => <HintContainer key={hint}>{hint}</HintContainer>)}
        <InputContainer>
          <WrappedComponent {...props} />
          {isCheckbox && label && (
            <Label isCheckboxLabel={true}>
              <FormattedMessage id={label} />
            </Label>
          )}
        </InputContainer>
        {hasError && <Error>{error}</Error>}
      </Row>
    );
  };

  return WrappedComponentWithRow;
};

export default withRow;
