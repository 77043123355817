import React, { useEffect } from 'react';
import Input from 'components/Input';
import { getError } from 'components/formUtils';
import Textarea from 'components/Textarea';
import { getModalPlanning } from 'module/Modal';
import { useSelector } from 'react-redux';
import RadioButtonGroup from 'components/RadioButtonGroup';
import RadioButton from 'components/RadioButton';
import { FormikProps } from 'formik';
import { RootState } from 'redux/types';
import { getIsPeopleFromTeam, Teams } from 'module/Teams';
import { PROJECT_TYPES, projectSelectors } from 'module/Project';
import { placeSelectors } from 'module/Place';
import { useUpdateEffect } from 'react-use';
import { FormFields, InnerStaffingFormProps } from './StaffingForm.form';
import FeesForm from './StaffingForm.fee.form';
import { INVOICED_INPUT_VALUES } from './StaffingForm.invoicing.form';
import { StaffingFormValues } from '.';

enum FEES_INPUT_VALUES {
  YES = 'oui',
  NO = 'non',
}

const GenericForm: React.FunctionComponent<
  InnerStaffingFormProps & FormikProps<StaffingFormValues>
> = props => {
  const { errors, touched, intl, values, setFieldValue, isEditionDisabled } = props;
  const planning = useSelector(getModalPlanning);
  const isTeamChatelain = useSelector(getIsPeopleFromTeam(planning.peopleId, Teams.CHATELAIN));

  const planningDate = new Date(planning.staffingDate);
  const today = new Date();
  const isFeesFieldUntouched = !values.hasFees;
  const isProjectFieldUntouched = !values.project;
  const isPlanningDateFuture = +planningDate > +today;

  const isMealVoucherEditable =
    !isPlanningDateFuture &&
    ((!isProjectFieldUntouched && !isFeesFieldUntouched) || isTeamChatelain) &&
    !isEditionDisabled;

  const hasConflictOrReservation = !!values.isConflict || !!values.isReservation;
  const isConsultantConfirmationBlocked = hasConflictOrReservation;
  const isValidationDisabled =
    isPlanningDateFuture ||
    isFeesFieldUntouched ||
    isProjectFieldUntouched ||
    hasConflictOrReservation;

  const selectedProjectId = values && values.project;
  const selectedProject = useSelector((state: RootState) =>
    projectSelectors.selectById(state, selectedProjectId),
  );

  const selectedPlaceId = values && values.place;
  const selectedPlace = useSelector((state: RootState) =>
    placeSelectors.selectById(state, selectedPlaceId),
  );

  const SelectedPlaceName = selectedPlace ? selectedPlace.name : values.place;

  useEffect(() => {
    if (!values.mealVoucher && isMealVoucherEditable) {
      if (selectedProject?.type === PROJECT_TYPES.ABSENCE) {
        setFieldValue(FormFields.mealVoucher, 'non');
      } else if (selectedProject?.name.toLowerCase().includes('interlude')) {
        setFieldValue(FormFields.mealVoucher, 'oui');
      } else if (selectedProject?.type === PROJECT_TYPES.INTERNAL) {
        setFieldValue(FormFields.mealVoucher, 'non');
      } else if (values.invoiced === INVOICED_INPUT_VALUES.YES) {
        setFieldValue(FormFields.mealVoucher, 'oui');
      } else if (SelectedPlaceName === 'Distanciel') {
        setFieldValue(FormFields.mealVoucher, 'non');
      } else {
        setFieldValue(FormFields.mealVoucher, isTeamChatelain ? 'non' : 'oui');
      }
    }
  }, [values]);

  useUpdateEffect(() => {
    if (isValidationDisabled) setFieldValue(FormFields.consultantValidation, false);
  }, [isValidationDisabled]);

  let consultantValidationHints: string[] = [];
  if (isFeesFieldUntouched) {
    consultantValidationHints = [
      ...consultantValidationHints,
      intl.formatMessage({ id: 'staffingForm.hints.fillFeesFirst' }),
    ];
  }

  if (isProjectFieldUntouched) {
    consultantValidationHints = [
      ...consultantValidationHints,
      intl.formatMessage({ id: 'staffingForm.hints.fillProjectFirst' }),
    ];
  }

  if (isPlanningDateFuture) {
    consultantValidationHints = [
      ...consultantValidationHints,
      intl.formatMessage({ id: 'staffingForm.hints.waitForPlanningDate' }),
    ];
  }

  const handleHasFeesChange = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const previousValueIsYes = values.hasFees === FEES_INPUT_VALUES.YES;
    const HasFeeEntities =
      values.feeList && Array.isArray(values.feeList) && values.feeList.length > 0;

    if (previousValueIsYes && HasFeeEntities) {
      const isDeletionConfirmed = confirm(
        intl.formatMessage({ id: 'staffingForm.fee.deletionConfirmation' }),
      );
      if (isDeletionConfirmed) {
        setFieldValue(FormFields.feeList, []);
        setFieldValue(FormFields.hasFees, FEES_INPUT_VALUES.NO);
      } else {
        e.preventDefault();
      }
    }
  };

  return (
    <>
      <Textarea
        name={FormFields.personalConstraints}
        label="staffingForm.personalConstraints"
        placeholder={intl.formatMessage({ id: 'staffingForm.personalConstraints-placeholder' })}
        error={getError(!!touched.personalConstraints, errors.personalConstraints)}
        disabled={isEditionDisabled}
      />
      <Input
        name={FormFields.workAtHome}
        type="checkbox"
        label="staffingForm.workAtHome"
        checked={!!values.workAtHome}
        error={getError(!!touched.workAtHome, errors.workAtHome)}
        disabled={isEditionDisabled}
      />
      <RadioButtonGroup
        name={FormFields.hasFees}
        label="staffingForm.hasFees"
        error={getError(!!touched.hasFees, errors.hasFees)}
      >
        {Object.values(FEES_INPUT_VALUES).map(hasFeesInputValue => (
          <RadioButton
            key={hasFeesInputValue}
            id={'fee' + hasFeesInputValue}
            name={FormFields.hasFees}
            value={hasFeesInputValue}
            onMouseDown={handleHasFeesChange}
            checked={values.hasFees === hasFeesInputValue}
            disabled={isEditionDisabled}
          />
        ))}
      </RadioButtonGroup>

      {values.hasFees === FEES_INPUT_VALUES.YES && (
        <FeesForm {...props} disabled={isEditionDisabled} />
      )}

      <Input
        name={FormFields.consultantConfirmation}
        type="checkbox"
        label="staffingForm.consultantConfirmation"
        checked={!!values.consultantConfirmation}
        error={getError(!!touched.consultantConfirmation, errors.consultantConfirmation)}
        disabled={isConsultantConfirmationBlocked || isEditionDisabled}
      />

      <RadioButtonGroup
        name={FormFields.mealVoucher}
        label="staffingForm.mealVoucher"
        error={getError(!!touched.mealVoucher, errors.mealVoucher)}
      >
        <RadioButton
          disabled={!isMealVoucherEditable}
          key="oui"
          id="mealVoucher_oui"
          name={FormFields.mealVoucher}
          value="oui"
          checked={values.mealVoucher === 'oui'}
        />
        <RadioButton
          disabled={!isMealVoucherEditable}
          key="non"
          id="mealVoucher_non"
          name={FormFields.mealVoucher}
          value="non"
          checked={values.mealVoucher === 'non'}
        />
      </RadioButtonGroup>
      <Input
        name={FormFields.consultantValidation}
        type="checkbox"
        label="staffingForm.consultantValidation"
        checked={!!values.consultantValidation}
        disabled={isValidationDisabled || isEditionDisabled}
        hints={consultantValidationHints}
        error={getError(!!touched.consultantValidation, errors.consultantValidation)}
      />
    </>
  );
};

export default GenericForm;
