import { useDispatch, useSelector } from 'react-redux';
import { getSnackBars } from './selectors';
import { pushErrorSnackBar, removeSnackBar } from './slice';

export const useDisplayErrorSnackBar = () => {
  const dispatch = useDispatch();
  return (text: string) => dispatch(pushErrorSnackBar(text));
};

export const useDismissSnackBar = () => {
  const dispatch = useDispatch();
  return (id: string) => dispatch(removeSnackBar(id));
};

export const useSnackBars = () => useSelector(getSnackBars);
