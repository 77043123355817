import { TABLES } from 'module/serializer';
import { useDispatch } from 'react-redux';
import { useTypedAsyncFn } from 'redux/useTypedAsyncFn';
import client, { DATA_ENDPOINT } from 'services/networking/client';
import { fetchEquipmentSuccess } from './slice';
import { Equipment } from './typings';

export const useGetEquipments = () => {
  const dispatch = useDispatch();

  return useTypedAsyncFn<void>(async () => {
    const equipments: Equipment[] = await client.get(DATA_ENDPOINT + TABLES.EQUIPMENT);
    if (equipments) {
      dispatch(fetchEquipmentSuccess(equipments));
    } else {
      throw new Error('error while trying to get Equipment');
    }
    return equipments;
  }, [dispatch]);
};

export default { useGetEquipments };
