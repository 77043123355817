import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormikProps } from 'formik';
import Autocomplete from 'components/Autocomplete';
import Input from 'components/Input';
import { getError } from 'components/formUtils';
import { Project, projectSelectors, useGetActiveProjects } from 'module/Project';
import { RootState } from 'redux/types';
import { FormFields, InnerStaffingFormProps } from './StaffingForm.form';
import { StaffingFormValues } from '.';
import { StaffingFormAlertButton } from './StaffingForm.form.style';

import { updateFieldsBasedOnSelectedProject } from './StaffingForm.form';

const SharedForm: React.FunctionComponent<
  InnerStaffingFormProps & FormikProps<StaffingFormValues>
> = ({ errors, touched, intl, values, setFieldValue, isEditionDisabled }) => {
  const projects = useGetActiveProjects();
  const selectedConflictingProjectId = values && values.conflictingProjectId;
  const selectedConflictingProject = useSelector((state: RootState) =>
    projectSelectors.selectById(state, selectedConflictingProjectId),
  );
  const SelectedConflictingProjectName = selectedConflictingProject
    ? selectedConflictingProject.name
    : values.conflictingProjectId;
  const getValueFromItem = (projectName: string) => {
    const projectValue = projects.filter((project: Project) => project.name === projectName)[0];
    return projectValue ? projectValue.id : projectName;
  };

  useEffect(() => {
    if (!values.isConflict) {
      setFieldValue(FormFields.conflictingProjectId, '');
    }
  }, [values.isConflict]);

  const onResolve = () => {
    setFieldValue(FormFields.project, selectedConflictingProjectId);
    if (selectedConflictingProject)
      updateFieldsBasedOnSelectedProject(setFieldValue, selectedConflictingProject);
    setFieldValue(FormFields.isConflict, false);
    setFieldValue(FormFields.conflictingProjectId, '');
    setFieldValue(FormFields.place, '');
  };

  return (
    <>
      <Input
        name={FormFields.isReservation}
        type="checkbox"
        label="staffingForm.isReservation"
        checked={!!values.isReservation}
        error={getError(!!touched.isReservation, errors.isReservation)}
        disabled={isEditionDisabled}
      />

      <Input
        name={FormFields.isConflict}
        type="checkbox"
        label="staffingForm.isConflict"
        checked={!!values.isConflict}
        error={getError(!!touched.isConflict, errors.isConflict)}
        disabled={isEditionDisabled}
      />

      {values.isConflict && (
        <Autocomplete
          label="staffingForm.conflictingProjectId"
          placeholder={intl.formatMessage({ id: 'staffingForm.project-placeholder' })}
          name={FormFields.conflictingProjectId}
          items={projects}
          getItemValue={(project: Project) => project.name}
          value={SelectedConflictingProjectName}
          setFieldValue={setFieldValue}
          getValueFromItem={getValueFromItem}
          reducedSize={true}
          inputProps={{ disabled: isEditionDisabled }}
        />
      )}

      {!isEditionDisabled && selectedConflictingProject && (
        <StaffingFormAlertButton onClick={onResolve}>
          {intl.formatMessage({ id: 'staffingForm.resolve' })}
        </StaffingFormAlertButton>
      )}
    </>
  );
};

export default SharedForm;
