import Button from 'components/Button';
import { SnackBarType } from 'module/SnackBars';
import React from 'react';
import { SnackBarContainer, SnackBarText } from './SnackBar.style';

export type SnackBarProps = {
  text: string;
  type: SnackBarType;
  onDismiss?: () => void;
};

const SnackBar = ({ text, type, onDismiss }: SnackBarProps) => {
  return (
    <SnackBarContainer type={type}>
      <SnackBarText>{text}</SnackBarText>
      <Button onClick={onDismiss}>Ok!</Button>
    </SnackBarContainer>
  );
};

export default SnackBar;
