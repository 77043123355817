import { combineReducers } from 'redux';
import { reducer as UI } from 'module/UI';
import { reducer as login } from 'module/Login';
import { reducer as people } from 'module/People';
import { reducer as teams } from 'module/Teams';
import { reducer as grid } from 'module/Grid';
import { reducer as modal } from 'module/Modal';
import { reducer as snackBars } from 'module/SnackBars';
import { reducer as planning } from 'module/Planning';
import { reducer as project } from 'module/Project';
import { reducer as client } from 'module/Client';
import { reducer as filter } from 'module/Filter';
import { reducer as fee } from 'module/Fee';
import { reducer as place } from 'module/Place';
import { reducer as equipment } from 'module/Equipment';

/**
 * Example of the Login module which should export a reducer.
 */

/**
 * Creates the main reducer with the asynchronously loaded ones
 */

const rootReducers = combineReducers({
  UI,
  login,
  people,
  teams,
  grid,
  modal,
  snackBars,
  planning,
  project,
  client,
  filter,
  fee,
  place,
  equipment,
});

export default rootReducers;
