import Header from 'components/Header';
import Modal from 'components/Modal';
import SnackBars from 'components/SnackBars';
import {
  updateCustomPeopleFilter,
  updateCustomPeopleSavedSelections,
  updatePeopleFilterType,
  updateValidationTimekeeping,
  updateWorkAtHome,
} from 'module/Filter';
import { FILTERS_LOCAL_STORAGE_KEYS } from 'module/Filter/constants';
import { useDisplayErrorSnackBar } from 'module/SnackBars';
import React, { ReactNode, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ActionCreator, Dispatch } from 'redux';
import { flattenMessages } from 'services/i18n/intl';
import client from 'services/networking/client';
import enMessages from 'translations/en.json';
import frMessages from 'translations/fr.json';
import { PageContent, RootContainer } from './Root.style';

const LOGIN_PAGE_PATHNAME = '/login';

const locales = {
  fr: flattenMessages(frMessages),
  en: flattenMessages(enMessages),
};

const LOCALE = 'fr';
interface Props {
  children: ReactNode;
}

const dispatchStorageValue = (
  key: string,
  dispatcher: Dispatch<any>,
  actionCreator: ActionCreator<any>,
) => {
  const value = localStorage.getItem(key);
  if (value !== null) {
    dispatcher(actionCreator(JSON.parse(value)));
  }
};

const Root: React.FunctionComponent<Props> = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatchStorageValue(FILTERS_LOCAL_STORAGE_KEYS.WORK_AT_HOME, dispatch, updateWorkAtHome);
    dispatchStorageValue(
      FILTERS_LOCAL_STORAGE_KEYS.VALIDATION_TIMEKEEPING,
      dispatch,
      updateValidationTimekeeping,
    );
    dispatchStorageValue(
      FILTERS_LOCAL_STORAGE_KEYS.PEOPLE_FILTER_TYPE,
      dispatch,
      updatePeopleFilterType,
    );
    dispatchStorageValue(
      FILTERS_LOCAL_STORAGE_KEYS.CUSTOM_PEOPLE_FILTER,
      dispatch,
      updateCustomPeopleFilter,
    );
    dispatchStorageValue(
      FILTERS_LOCAL_STORAGE_KEYS.CUSTOM_PEOPLE_SAVED_SELECTION,
      dispatch,
      updateCustomPeopleSavedSelections,
    );
  }, []);

  // display an error message when the networking client gives us an error
  const displayErrorSnackBar = useDisplayErrorSnackBar();
  useEffect(
    () =>
      client.on('error', () => {
        displayErrorSnackBar(locales[LOCALE]['error.errorAPI']);
      }),
    [displayErrorSnackBar],
  );

  // see the following issue:
  // https://dev.to/vvo/how-to-solve-window-is-not-defined-errors-in-react-and-next-js-5f97
  let page = '';
  if (typeof window !== 'undefined') {
    page = window.location.pathname;
  }

  return (
    <IntlProvider locale={LOCALE} messages={locales[LOCALE]}>
      <RootContainer>
        {page !== LOGIN_PAGE_PATHNAME && <Header />}
        <PageContent>{children}</PageContent>
        <Modal />
        <SnackBars />
      </RootContainer>
    </IntlProvider>
  );
};

export default Root;
