import React from 'react';
import { useIntl } from 'react-intl';
import DatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import { FiArrowRight } from 'react-icons/fi';
import { RangePickerContainer, StyledInput } from './RangePicker.style';

registerLocale('fr', fr);

interface Props {
  selectedStartDate: Date;
  selectedEndDate: Date;
  startDateChangeHandler: Function;
  endDateChangeHandler: Function;
}

const RangePicker: React.FunctionComponent<Props> = ({
  selectedStartDate,
  selectedEndDate,
  startDateChangeHandler,
  endDateChangeHandler,
}) => {
  const intl = useIntl();

  return (
    <RangePickerContainer>
      <DatePicker
        selected={selectedStartDate}
        onChange={(date: Date) => startDateChangeHandler(date)}
        selectsStart
        startDate={selectedStartDate}
        endDate={selectedEndDate}
        customInput={<StyledInput />}
        withPortal
        locale={intl.locale}
        dateFormat="dd/MM/yyyy"
        onFocus={e => (e.target.readOnly = true)}
      />
      <FiArrowRight />
      <DatePicker
        selected={selectedEndDate}
        onChange={(date: Date) => endDateChangeHandler(date)}
        selectsEnd
        startDate={selectedStartDate}
        endDate={selectedEndDate}
        minDate={selectedStartDate}
        customInput={<StyledInput />}
        withPortal
        locale={intl.locale}
        dateFormat="dd/MM/yyyy"
        onFocus={e => (e.target.readOnly = true)}
      />
    </RangePickerContainer>
  );
};

export default RangePicker;
