import { FormikProps } from 'formik';
import React, { useEffect } from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';
import { Project, PROJECT_TYPES, projectSelectors, useGetActiveProjects } from 'module/Project';
import { useDispatch, useSelector } from 'react-redux';
import Textarea from 'components/Textarea';
import { getError } from 'components/formUtils';
import { RootState } from 'redux/types';
import { Planning } from 'module/Planning';
import Autocomplete from 'components/Autocomplete';
import Loader from 'components/Loader';
import { setModalChanged } from 'module/Modal';
import { useUpdateEffect } from 'react-use';
import {
  CubikFormArea,
  StaffingForm,
  StaffingFormAreaTitle,
  StaffingFormButton,
  StaffingFormButtonContainer,
  StaffingFormContainer,
} from './StaffingForm.form.style';
import { StaffingFormValues } from './service';
import InvoicingForm, {
  INVOICED_FREELANCE_INPUT_VALUES,
  INVOICED_INPUT_VALUES,
} from './StaffingForm.invoicing.form';
import TrainingForm from './StaffingForm.training.form';
import LinksForm from './StaffingForm.links.form';
import GenericForm from './StaffingForm.generic.form';
import SharedForm from './StaffingForm.shared.form';
import FormEquipment from './StaffingForm.equipment';
import FormPlace from './StaffingForm.place';
import StaffedWithMe from './StaffingForm.staffedWithMe';

export enum FormFields {
  project = 'project',
  personalConstraints = 'personalConstraints',
  hasFees = 'hasFees',
  interventionType = 'interventionType',
  invoiced = 'invoiced',
  invoicedFreelance = 'invoicedFreelance',
  details = 'details',
  brief = 'brief',
  dayNumber = 'dayNumber',
  place = 'place',
  specificMaterial = 'specificMaterial',
  workAtHome = 'workAtHome',
  consultantConfirmation = 'consultantConfirmation',
  consultantValidation = 'consultantValidation',
  feeList = 'feeList',
  isReservation = 'isReservation',
  isConflict = 'isConflict',
  conflictingProjectId = 'conflictingProjectId',
  seller = 'seller',
  mealVoucher = 'mealVoucher',
}

export interface InnerStaffingFormProps {
  errors: Partial<Record<FormFields, string>>;
  touched: Partial<Record<FormFields, boolean>>;
  submit: (values: StaffingFormValues) => void;
  intl: IntlShape;
  values: Record<FormFields, string | boolean | number | any[]>;
  loading: boolean;
  planning: Planning;
  hints?: string[];
  isEditionDisabled?: boolean;
}

export const updateFieldsBasedOnSelectedProject = (
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  selectedProject: Project,
) => {
  if (
    selectedProject.type === PROJECT_TYPES.TRAINING ||
    selectedProject.type === PROJECT_TYPES.CONSULTING
  ) {
    setFieldValue(FormFields.invoiced, INVOICED_INPUT_VALUES.YES);
  } else {
    setFieldValue(FormFields.invoiced, INVOICED_INPUT_VALUES.NO);
  }

  if (selectedProject.type !== PROJECT_TYPES.INTERNAL) {
    setFieldValue(FormFields.invoicedFreelance, INVOICED_FREELANCE_INPUT_VALUES.NO);
  }
};

const InnerStaffingFormForm: React.FunctionComponent<
  InnerStaffingFormProps & FormikProps<StaffingFormValues>
> = props => {
  const { errors, touched, values, intl, setFieldValue, loading, isEditionDisabled, planning } =
    props;
  const projects = useGetActiveProjects();
  const selectedProjectId = values && values.project;
  const selectedProject = useSelector((state: RootState) =>
    projectSelectors.selectById(state, selectedProjectId),
  );

  const dispatch = useDispatch();

  const SelectedProjectName = selectedProject ? selectedProject.name : values.project;

  useEffect(() => {
    if (selectedProject) {
      props.setFieldValue(FormFields.brief, selectedProject.briefLink);
      props.setFieldValue(FormFields.seller, selectedProject.seller);
    }
  }, [props.values.project]);

  // set default values when project changes
  useUpdateEffect(() => {
    if (!selectedProject) return;
    updateFieldsBasedOnSelectedProject(setFieldValue, selectedProject);
  }, [selectedProject]);

  const isConsultingProject: boolean =
    !!selectedProject && selectedProject.type === PROJECT_TYPES.CONSULTING;
  const hasDetail: boolean =
    !!selectedProject &&
    [PROJECT_TYPES.INTERNAL, PROJECT_TYPES.CONSULTING, PROJECT_TYPES.TRAINING].includes(
      selectedProject.type,
    );
  const isTrainingProject: boolean =
    !!selectedProject && selectedProject.type === PROJECT_TYPES.TRAINING;
  const isInternal = !!selectedProject && selectedProject.type === PROJECT_TYPES.INTERNAL;
  const hasProjectLinks: boolean = isTrainingProject || isConsultingProject;

  const getValueFromItem = (projectName: string) => {
    const projectValue = projects.filter((project: Project) => project.name === projectName)[0];
    return projectValue ? projectValue.id : projectName;
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(setModalChanged(false));
    });
  }, []);

  useEffect(() => {
    dispatch(setModalChanged(true));
  }, [values]);

  return (
    <StaffingFormContainer>
      {loading ? (
        <Loader big={true} />
      ) : (
        <StaffingForm translate="yes">
          <StaffingFormAreaTitle>
            {intl.formatMessage({ id: 'staffingForm.areas.staffer' })}
          </StaffingFormAreaTitle>
          <hr />
          <Autocomplete
            label="staffingForm.project"
            placeholder={intl.formatMessage({ id: 'staffingForm.project-placeholder' })}
            name="project"
            items={projects}
            getItemValue={(project: Project) => project.name}
            value={SelectedProjectName}
            setFieldValue={setFieldValue}
            getValueFromItem={getValueFromItem}
            inputProps={{ disabled: isEditionDisabled }}
          />
          {isTrainingProject && <TrainingForm selectedProjet={selectedProject} {...props} />}
          {(isConsultingProject || isTrainingProject || isInternal) && (
            <>
              <InvoicingForm {...props} />
              <FormPlace
                intl={intl}
                setFieldValue={setFieldValue}
                values={values}
                isEditionDisabled={isEditionDisabled}
              />
            </>
          )}
          {selectedProject?.type === PROJECT_TYPES.TRAINING && (
            <StaffedWithMe projectId={values.project} planning={planning} />
          )}
          {!!selectedProject && <SharedForm {...props} />}
          {isTrainingProject && (
            <FormEquipment
              intl={intl}
              values={values}
              setFieldValue={setFieldValue}
              isEditionDisabled={isEditionDisabled}
            />
          )}
          {hasProjectLinks && <LinksForm {...props} />}
          {hasDetail && (
            <Textarea
              name={FormFields.details}
              label="staffingForm.details"
              placeholder={intl.formatMessage({ id: 'staffingForm.details-placeholder' })}
              error={getError(!!touched.details, errors.details)}
              disabled={isEditionDisabled}
            />
          )}
          <CubikFormArea>
            <StaffingFormAreaTitle>
              {intl.formatMessage({ id: 'staffingForm.areas.cubik' })}
            </StaffingFormAreaTitle>
            <hr />
            <GenericForm {...props} />
          </CubikFormArea>
          {!isEditionDisabled && (
            <StaffingFormButtonContainer>
              <StaffingFormButton
                disabled={isEditionDisabled}
                id="staffing-form-button"
                type="submit"
              >
                <FormattedMessage id="staffingForm.staffingForm-button" />
              </StaffingFormButton>
            </StaffingFormButtonContainer>
          )}
        </StaffingForm>
      )}
    </StaffingFormContainer>
  );
};

export default InnerStaffingFormForm;
