// @ts-ignore
import logo from 'assets/cubik-partners.jpg';
import CreateIcon from '@material-ui/icons/Create';
// @ts-ignore see https://github.com/TeamWertarbyte/material-ui-toggle-icon/issues/17
import ToggleIcon from 'material-ui-toggle-icon';
import IconButton from '@material-ui/core/IconButton';

import FilterButton from 'components/FilterButton';
import { isLoggedIn, useLogout } from 'module/Login';
import Link from 'next/link';
import React from 'react';
import ConnectedRangePicker from 'components/ConnectedRangePicker';
import { useSelector } from 'react-redux';
import ExportButton from 'components/ExportButton';
import ExportCostsButton from 'components/ExportCostsButton';
import { useIsEditionBlocked, useToggleEditionBlocked } from 'module/UI';
import { colorPalette } from 'stylesheet';
import {
  HeaderActionsContainer,
  HeaderContainer,
  Logo,
  RangePickerContainer,
  StyledPowerSettingsNewIcon,
} from './Header.style';
import EditOffIcon from './EditOffIcon';

const Header: React.FunctionComponent = () => {
  const logout = useLogout();
  const isUserLoggedIn = useSelector(isLoggedIn);
  const editionBlocked = useIsEditionBlocked();
  const toggleEditionBlocked = useToggleEditionBlocked();

  return (
    <HeaderContainer>
      <Link href="/">
        <Logo alt="cubiks-partners logo" src={logo} />
      </Link>
      {isUserLoggedIn && (
        <RangePickerContainer>
          <ConnectedRangePicker />
        </RangePickerContainer>
      )}
      {isUserLoggedIn && (
        <HeaderActionsContainer>
          <IconButton onClick={toggleEditionBlocked}>
            <ToggleIcon
              on={!editionBlocked}
              onIcon={
                // needs to be wrapeed inside a <span />, otherwise <ToggleIcon /> will override
                // <CreateIcon/>'s `style` prop
                <span>
                  <CreateIcon style={{ color: colorPalette.validationGreen }} />
                </span>
              }
              offIcon={<EditOffIcon fill={colorPalette.white} />}
            />
          </IconButton>
          <FilterButton />
          <ExportButton />
          <ExportCostsButton />
          <StyledPowerSettingsNewIcon onClick={logout} />
        </HeaderActionsContainer>
      )}
    </HeaderContainer>
  );
};

export default Header;
