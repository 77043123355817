import styled from 'styled-components';
import { colorPalette, getSpacing } from 'stylesheet';
import { Fab } from '@material-ui/core';

export const FabButtonContainer = styled(Fab)`
  .MuiFab-primary {
    background-color: ${colorPalette.blueDark};
  }

  .fab-icon {
    background-color: ${colorPalette.white};
    font-size: ${getSpacing(3)};
  }
`;
