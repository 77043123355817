import { TABLES } from 'module/serializer';
import { useDispatch } from 'react-redux';
import { useTypedAsyncFn } from 'redux/useTypedAsyncFn';
import client, { DATA_ENDPOINT } from 'services/networking/client';
import { fetchPlaceSuccess } from './slice';
import { Place } from './typings';

export const useGetPlaces = () => {
  const dispatch = useDispatch();

  return useTypedAsyncFn<void>(async () => {
    const places: Place[] = await client.get(DATA_ENDPOINT + TABLES.PLACE);
    if (places) {
      dispatch(fetchPlaceSuccess(places));
    } else {
      throw new Error('error while trying to get Place');
    }
    return places;
  }, [dispatch]);
};

export default { useGetPlaces };
