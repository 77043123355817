import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import styled from 'styled-components';
import { colorPalette, getSpacing, HEADER_HEIGHT, size, typography } from 'stylesheet';

export const HeaderContainer = styled.header`
  ${typography.h1}
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: ${HEADER_HEIGHT.MOBILE};
  padding: 0 ${getSpacing(6)};
  background-color: ${colorPalette.cubikBlue};
  color: ${colorPalette.white};

  @media (min-width: ${size.tablet}) {
    height: ${HEADER_HEIGHT.DESKTOP};
  }
`;

HeaderContainer.displayName = 'HeaderContainer';

export const Logo = styled.img`
  height: 100%;
`;

Logo.displayName = 'Logo';

export const RangePickerContainer = styled.div`
  display: none;

  @media (min-width: ${size.tablet}) {
    display: flex;
    width: ${getSpacing(75)};
  }
`;

export const HeaderActionsContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 0;
`;

export const StyledPowerSettingsNewIcon = styled(PowerSettingsNewIcon)`
  color: ${colorPalette.white};
  height: ${getSpacing(6)};
  margin-left: ${getSpacing(6)};
  cursor: pointer;

  &:hover {
    opacity: 0.4;
  }
`;
