import styled from 'styled-components';
import React from 'react';
import { WrappedInputProps } from 'components/InputsUtils/types';

const StyledRadioButtonGroup = styled.div<WrappedInputProps>`
  display: flex;
  flex-direction: column;
`;

StyledRadioButtonGroup.displayName = 'StyledRadioButtonGroup';

const RadioButtonGroup: React.FunctionComponent<WrappedInputProps> = (props: WrappedInputProps) => (
  <StyledRadioButtonGroup {...props} />
);

export default RadioButtonGroup;
