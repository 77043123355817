import { peopleSelectors } from 'module/People';
import { Planning, StaffedWithMeResponseType, useGetStaffedWithMeProjects } from 'module/Planning';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/types';

type Props = { projectId: string; planning: Planning };

const StaffedWithMe = ({ projectId, planning }: Props) => {
  const intl = useIntl();

  const [{ value }, getPlannings] = useGetStaffedWithMeProjects();

  const plannings = value as StaffedWithMeResponseType | undefined;

  const peopleList = useSelector((state: RootState) =>
    plannings?.map(_planning => ({
      user: peopleSelectors.selectById(state, _planning.peopleId),
      ..._planning,
    })),
  );

  useEffect(() => {
    getPlannings({
      projectId,
      peopleId: planning.peopleId,
      staffingDate: planning.staffingDate,
      isMerged: planning.isMerged,
      isAM: planning.isAM,
    });
  }, [getPlannings, planning, projectId]);

  if (!peopleList) return null;

  if (peopleList.length === 0)
    return <div>{intl.formatMessage({ id: 'staffingForm.staffedAlone' })}</div>;

  return (
    <div>
      {intl.formatMessage({ id: 'staffingForm.staffedWith' })}
      {peopleList.map(({ id, peopleId, user, isAM, isMerged }) => (
        <div key={id}>
          {user ? `${user.firstName} ${user.lastName}` : peopleId} (
          {isMerged
            ? intl.formatMessage({ id: 'staffingForm.day' })
            : isAM
            ? intl.formatMessage({ id: 'staffingForm.am' })
            : intl.formatMessage({ id: 'staffingForm.pm' })}
          )
        </div>
      ))}
    </div>
  );
};

export default StaffedWithMe;
