import React from 'react';
import Input from 'components/Input';
import { getError } from 'components/formUtils';
import { FormFields, InnerStaffingFormProps } from './StaffingForm.form';
import { StaffingFormLink } from './StaffingForm.links.form.style';

const LinksForm: React.FunctionComponent<InnerStaffingFormProps> = ({
  errors,
  touched,
  intl,
  values,
}) => {
  return (
    <>
      <StaffingFormLink href={values.brief?.toString()} target="_blank" rel="noreferrer">
        <Input
          disabled={true}
          name={FormFields.brief}
          label="staffingForm.brief"
          placeholder={intl.formatMessage({ id: 'staffingForm.brief-placeholder' })}
          error={getError(!!touched.brief, errors.brief)}
        />
      </StaffingFormLink>
      <Input
        disabled={true}
        name={FormFields.seller}
        label="staffingForm.seller"
        placeholder={intl.formatMessage({ id: 'staffingForm.seller-placeholder' })}
        error={getError(!!touched.seller, errors.seller)}
      />
    </>
  );
};

export default LinksForm;
