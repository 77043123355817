import styled from 'styled-components';
import { borderRadius, colorPalette, getSpacing, size, typography } from 'stylesheet';

const RANGE_PICKER_HEIGHT = 32;
const RANGE_PICKER_HEIGHT_DESKTOP = 24;

export const RangePickerContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  .react-datepicker__input-container {
    display: flex;
  }

  /*
  we need that the calendar top-edge position
  is fixed so that we can quickly click on next month
  */
  .react-datepicker__portal {
    align-items: flex-start;
    padding-top: 10vh;
  }
`;

export const StyledInput = styled.input`
  ${typography.main}
  font-size: 16px;
  padding: 0 ${getSpacing(3)};
  border-radius: ${borderRadius.medium};
  border: 1px solid ${colorPalette.grey};
  width: 100%;
  height: ${RANGE_PICKER_HEIGHT}px;

  :hover {
    border-color: ${colorPalette.greyDark};
  }

  :focus {
    border-color: ${colorPalette.blueDark};
  }

  @media (min-width: ${size.tablet}) {
    font-size: ${getSpacing(3)};
    height: ${RANGE_PICKER_HEIGHT_DESKTOP}px;
  }
`;
