import FilterModal from 'components/FilterModal';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FilterButtonContainer, FilterIconContainer, FilterLabel } from './FilterButton.style';
import FilterIcon from './FilterIcon';

const FilterButton: React.FunctionComponent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <FilterModal isModalOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <FilterButtonContainer onClick={() => setIsModalOpen(true)}>
        <FilterIconContainer>
          <FilterIcon size={16} />
        </FilterIconContainer>
        <FilterLabel>
          <FormattedMessage id="header.filter" />
        </FilterLabel>
      </FilterButtonContainer>
    </>
  );
};

export default FilterButton;
