import styled from 'styled-components';
import { borderRadius, colorPalette, getSpacing, typography } from 'stylesheet';
import React from 'react';
import { WrappedInputProps } from 'components/InputsUtils/types';

const getBorderColor = (originalColor: string, error?: string): string =>
  error ? colorPalette.red : originalColor;

const StyledSelect = styled.select<WrappedInputProps>`
  ${typography.main}
  width: 100%;
  height: 60px;
  background-color: ${colorPalette.white};
  padding: 0 ${getSpacing(3)};
  border-radius: ${borderRadius.medium};
  border: 1px solid ${({ error }) => getBorderColor(colorPalette.grey, error)};
  -webkit-appearance: none;
  :hover {
    border-color: ${({ error }) => getBorderColor(colorPalette.greyDark, error)};
  }

  :focus {
    border-color: ${({ error }) => getBorderColor(colorPalette.blueDark, error)};
  }

  ::placeholder {
    color: ${colorPalette.grey};
  }
`;

StyledSelect.displayName = 'StyledSelect';

const Select: React.FunctionComponent<WrappedInputProps> = (props: WrappedInputProps) => (
  <StyledSelect {...props}>
    {props.options &&
      props.options.length > 0 &&
      props.options.map(option => (
        <option key={option} value={option}>
          {option === '' ? props.placeholder : option}
        </option>
      ))}
  </StyledSelect>
);

export default Select;
