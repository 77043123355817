import Button from 'components/Button';
import RadioButtonGroup from 'components/RadioButtonGroup';
import styled from 'styled-components';
import { colorPalette, getSpacing, size } from 'stylesheet';

export const ModalContainer = styled.div`
  max-width: 350px;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  background-color: white;
  outline: 0;
  margin: ${getSpacing(18)} auto 0 auto;

  @media (min-width: ${size.tablet}) {
    margin: 0;
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translateX(50%) translateY(-50%);
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colorPalette.blueDark};
  padding: ${getSpacing(3)};
  color: ${colorPalette.white};
`;

export const ModalContent = styled.div`
  padding: ${getSpacing(3)};
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${getSpacing(2)} 0;
`;

export const CustomPeopleFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CustomSelectionNameContainer = styled.div`
  margin-top: ${getSpacing(3)};
`;

export const RangePickerWrapper = styled.div`
  margin: ${getSpacing(3)} 0;

  @media (min-width: ${size.tablet}) {
    display: none;
  }
`;

export const StyledRadioButtonGroup = styled(RadioButtonGroup)`
  flex: 1;
`;

export const ConfirmButton = styled(Button)`
  width: 100%;
  margin-top: ${getSpacing(3)};
`;
