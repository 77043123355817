import Button from 'components/Button';
import { Form } from 'formik';
import styled from 'styled-components';
import { colorPalette, getSpacing, size } from 'stylesheet';

export const StaffingFormContainer = styled.div`
  height: 100%;
`;

export const StaffingForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: ${getSpacing(3)};

  @media (min-width: ${size.tablet}) {
    padding: ${getSpacing(10)};
  }
`;

export const StaffingFormButton = styled(Button)`
  align-self: center;
  background-color: ${props => (props.disabled ? colorPalette.greyLight : colorPalette.blueDark)};
  color: ${props => (props.disabled ? colorPalette.white : colorPalette.greyLight)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export const StaffingFormAlertButton = styled(StaffingFormButton)`
  && {
    background-color: ${colorPalette.validationOrange};

    &:hover {
      background-color: ${colorPalette.yellow};
    }
  }
`;

export const StaffingFormButtonContainer = styled.div`
  display: none;
  align-self: center;
  padding: ${getSpacing(8)} 0;

  @media (min-width: ${size.tablet}) {
    display: block;
    padding: ${getSpacing(10)};
  }
`;

export const CubikFormArea = styled.div`
  order: -1;
  width: 100%;

  @media (min-width: ${size.laptop}) {
    order: unset;
  }
`;

export const StaffingFormAreaTitle = styled.h2`
  text-align: center;

  & + hr {
    border: 0;
    border-bottom: 1px solid black;
    width: 100%;
  }
`;
