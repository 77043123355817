import styled from 'styled-components';
import { typography } from 'stylesheet';
import React from 'react';

interface Props {
  checked: boolean;
  name: string;
  value: string;
  id: string;
  onMouseDown?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  onChange?: () => void;
  label?: string;
  disabled?: boolean;
}

const StyledRadioButton = styled.div`
  ${typography.main}
`;

StyledRadioButton.displayName = 'StyledRadioButton';

const RadioButton: React.FunctionComponent<Props> = ({
  value,
  id,
  name,
  onMouseDown,
  onChange,
  checked,
  label,
  disabled,
}: Props) => (
  <StyledRadioButton>
    <input
      type="radio"
      id={id}
      checked={checked}
      name={name}
      value={value}
      onChange={onChange}
      onMouseDown={onMouseDown}
      disabled={disabled}
    />
    <label htmlFor={id}>{label ? label : value}</label>
  </StyledRadioButton>
);

export default RadioButton;
