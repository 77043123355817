import React, { ReactNode } from 'react';

import { FallbackProps } from 'components/AppCrashFallback/AppCrashFallback';
import { captureException } from 'services/sentry';

interface Props {
  children: ReactNode;
  FallbackComponent: React.ComponentType<FallbackProps>;
  hasError: boolean;
  eventId: string;
}
interface State {
  hasError: boolean;
  eventId: string;
}

class ErrorBoundary extends React.Component<Props, State> {
  state = {
    hasError: false,
    eventId: '',
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    // If there was an error generated within getInitialProps, and we haven't
    // yet seen an error, we add it to this.state here
    return {
      hasError: props.hasError || state.hasError || false,
      eventId: props.eventId || state.eventId || undefined,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const eventId = captureException(error, { errorInfo });
    this.setState({ eventId });
  }

  render() {
    const { hasError, eventId } = this.state;
    const { FallbackComponent, children } = this.props;
    return hasError ? <FallbackComponent eventId={eventId} /> : children;
  }
}

export default ErrorBoundary;
