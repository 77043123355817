import styled from 'styled-components';
import { borderRadius, colorPalette, getSpacing, typography } from 'stylesheet';
import { WrappedInputProps } from 'components/InputsUtils/types';
import React from 'react';

const getBorderColor = (originalColor: string, error?: string, disabled?: boolean): string =>
  disabled ? 'none' : error ? colorPalette.red : originalColor;

export const StyledInput = styled.input<WrappedInputProps>`
  ${typography.main}
  min-width: ${({ type }) => (type === 'checkbox' ? getSpacing(5) : '100%')};
  min-height: ${({ type }) => (type === 'checkbox' ? getSpacing(5) : getSpacing(15))};
  background-color: ${({ disabled, emphasized }) => {
    if (disabled) return colorPalette.grey;
    if (emphasized) return colorPalette.validationGreen;
    return colorPalette.white;
  }};
  padding: 0 ${getSpacing(3)};
  border-radius: ${borderRadius.medium};
  border: 1px solid ${({ error, disabled }) => getBorderColor(colorPalette.grey, error, disabled)};
  ::placeholder {
    color: ${colorPalette.grey};
  }
  ${({ error, disabled }) => `
  :hover {
    border-color: ${getBorderColor(colorPalette.greyDark, error, disabled)};}

  :focus {
    border-color: ${getBorderColor(colorPalette.blueDark, error, disabled)};
  }`}
`;

StyledInput.displayName = 'StyledInput';

const Input: React.FunctionComponent<WrappedInputProps> = ({
  type,
  disabled,
  ...props
}: WrappedInputProps) => <StyledInput type={type ? type : 'text'} disabled={disabled} {...props} />;

export default Input;
