import styled from 'styled-components';
import { colorPalette, getSpacing, HEADER_HEIGHT, size } from 'stylesheet';

const CLOSE_LOGO_SIZE = 16;
const MODAL_WIDTH = 768;

export const ModalBackground = styled.div<{ isOpen: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100vw;
  pointer-events: ${props => (props.isOpen ? 'all' : 'none')};
  background-color: ${props => (props.isOpen ? 'rgba(0, 0, 0, 0.5)' : 'transparent')};
  transition: all 0.1s ease;
  z-index: 3;
`;

ModalBackground.displayName = 'ModalBackground';

export const ModalContainer = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: ${props => (props.isOpen ? '0' : '100%')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: ${MODAL_WIDTH}px;
  background-color: ${colorPalette.white};
  height: 100%;
  max-height: ${props => (props.isOpen ? '100%' : '0')};
  overflow-y: hidden;
  transition: all 0.3s ease;

  @media (min-width: ${size.tablet}) {
    top: ${HEADER_HEIGHT.DESKTOP};
    left: Calc(Calc(100% / 2) - Calc(${MODAL_WIDTH}px / 2));
    max-height: Calc(100% - 2 * ${HEADER_HEIGHT.DESKTOP});
    opacity: ${props => (props.isOpen ? 1 : 0)};
    transition: all 0.1s ease;
  }

  .fab {
    position: absolute;
    bottom: ${getSpacing(2)};
    right: ${getSpacing(2)};
  }
`;

ModalContainer.displayName = 'ModalContainer';

export const CloseLogo = styled.img`
  height: ${CLOSE_LOGO_SIZE}px;
  width: ${CLOSE_LOGO_SIZE}px;
  filter: invert(1);
  cursor: pointer;
  flex: 0;
`;

CloseLogo.displayName = 'CloseButton';

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  background-color: ${colorPalette.blueDark};
  align-self: stretch;
  padding: ${getSpacing(3)};

  @media (min-width: ${size.tablet}) {
    flex-direction: row;
  }
`;

ModalHeader.displayName = 'ModalHeader';

export const ModalTitle = styled.div`
  color: ${colorPalette.white};
  flex: 1;
  text-align: center;
`;

ModalTitle.displayName = 'ModalTitle';

export const SaveButton = styled.div`
  color: ${colorPalette.white};
  cursor: pointer;
  display: none;

  @media (min-width: ${size.tablet}) {
    display: block;
  }
`;

SaveButton.displayName = 'SaveButton';

export const ScrollableContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  overflow-y: scroll;
`;

ScrollableContent.displayName = 'ScrollableContent';

export const FabButtonContainer = styled.div`
  display: block;
  width: 100%;

  @media (min-width: ${size.tablet}) {
    display: none;
  }
`;

FabButtonContainer.displayName = 'FabButtonContainer';
