import { createStore } from 'redux';

import rootReducers from './reducers';

// Disable this rule because I don't have ownership of this property name.
/* eslint-disable no-underscore-dangle */
const debugMiddleware =
  typeof window !== 'undefined' &&
  '__REDUX_DEVTOOLS_EXTENSION__' in window &&
  typeof (window as any).__REDUX_DEVTOOLS_EXTENSION__ === 'function'
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION__()
    : undefined;
/* eslint-enable no-underscore-dangle */

function configureStore(
  initialState: any, // We need to put "any" because the RootState type is not compatible with the NextStoreCreator type applied with our middlewares
) {
  return createStore(rootReducers, initialState, debugMiddleware);
}

export default configureStore;
