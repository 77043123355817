import React from 'react';
import { useUpdateEffect } from 'react-use';
import { FieldArray, FormikProps } from 'formik';
import { FaTrash } from 'react-icons/fa';
import Input from 'components/Input';
import { useState } from 'react';
import Select from 'components/Select';
import { Fee, FeeType } from 'module/Fee';
import { FormFields, InnerStaffingFormProps } from './StaffingForm.form';
import {
  AddFeeButton,
  DeleteFeeButton,
  FeeFormHeader,
  FeeFormHeaderDesc,
  FeeFormHeaderPrice,
  FeeFormInputContainer,
  FeeLine,
  FeeLineForm,
  FeeLineFormContainer,
  FeesFormContainer,
} from './StaffingForm.fee.form.style';
import { StaffingFormValues } from '.';

enum FeeFormFields {
  description = 'description',
  tva = 'tva',
  total = 'total',
  type = 'type',
  distanceKm = 'distanceKm',
  volumeL = 'volumeL',
  roundtrip = 'roundtrip',
  numberOfNights = 'numberOfNights',
}

const tvaFeeTypes: FeeType[] = [
  FeeType.empty,
  FeeType.food,
  FeeType.accomodation,
  FeeType.tollFee,
  FeeType.gas,
  FeeType.gasoil,
  FeeType.furniture,
];

const distanceFeeTypes: FeeType[] = [
  FeeType.train,
  FeeType.flight,
  FeeType.taxi,
  FeeType.fossilFuelCarRental,
  FeeType.electricCarRental,
  FeeType.mileageAllowances,
];

const volumeFeeTypes: FeeType[] = [FeeType.gas, FeeType.gasoil];

const roundtripFeeTypes: FeeType[] = [FeeType.train, FeeType.flight];

const numberOfNightsFeeTypes: FeeType[] = [FeeType.accomodation];

const feeTypeOptions: FeeType[] = Object.values(FeeType);

const emptyFeeForm: Record<FeeFormFields, any> = {
  description: '',
  tva: 0,
  total: 0,
  type: '',
  distanceKm: undefined,
  volumeL: undefined,
  roundtrip: undefined,
  numberOfNights: undefined,
};

const RESET_FIELDS = {
  [FeeFormFields.tva]: {
    defaultValue: 0,
    typesWithField: tvaFeeTypes,
  },
  [FeeFormFields.distanceKm]: {
    defaultValue: undefined,
    typesWithField: distanceFeeTypes,
  },
  [FeeFormFields.volumeL]: {
    defaultValue: undefined,
    typesWithField: volumeFeeTypes,
  },
  [FeeFormFields.roundtrip]: {
    defaultValue: undefined,
    typesWithField: roundtripFeeTypes,
  },
  [FeeFormFields.numberOfNights]: {
    defaultValue: undefined,
    typesWithField: numberOfNightsFeeTypes,
  },
};

type FeesFormOwnProps = {
  disabled?: boolean;
};

const FeesForm: React.FunctionComponent<
  InnerStaffingFormProps & FormikProps<StaffingFormValues> & FeesFormOwnProps
> = ({ intl, values, setFieldValue, disabled }) => {
  const [shownFeeId, setShownFeeId] = useState(-1);

  // if the type of the shown fee changed and that it does not need any TVA,
  // we want to set the TVA to 0
  const shownFeeType = React.useMemo(() => {
    if (shownFeeId === -1) return null;
    if (!values.feeList || !Array.isArray(values.feeList)) return null;
    if (!values.feeList[shownFeeId]) return null;
    return (values.feeList[shownFeeId] as Fee).type;
  }, [shownFeeId, values]);
  useUpdateEffect(() => {
    for (const [field, { defaultValue, typesWithField }] of Object.entries(RESET_FIELDS)) {
      if (shownFeeType && !typesWithField.includes(shownFeeType)) {
        setFieldValue(`${FormFields.feeList}.${shownFeeId}.${field}`, defaultValue);
      }
    }
  }, [shownFeeType, setFieldValue]);

  return (
    <FieldArray
      name="feeList"
      render={arrayHelpers => (
        <FeesFormContainer>
          {values.feeList && Array.isArray(values.feeList) && values.feeList.length > 0 && (
            <>
              {values.feeList.map((fee, index) => (
                <FeeLineFormContainer key={index} isActive={shownFeeId === index}>
                  <FeeLine>
                    <FeeFormHeader onClick={() => setShownFeeId(shownFeeId === index ? -1 : index)}>
                      <FeeFormHeaderDesc>{fee.type}</FeeFormHeaderDesc>
                      <FeeFormHeaderPrice>{fee.total}€</FeeFormHeaderPrice>
                      {!disabled && (
                        <DeleteFeeButton type="button" onClick={() => arrayHelpers.remove(index)}>
                          <FaTrash />
                        </DeleteFeeButton>
                      )}
                    </FeeFormHeader>
                  </FeeLine>

                  <FeeLineForm displayed={shownFeeId === index}>
                    <FeeFormInputContainer>
                      <Select
                        label="staffingForm.fee.type"
                        name={`${FormFields.feeList}.${index}.${FeeFormFields.type}`}
                        placeholder={intl.formatMessage({
                          id: 'staffingForm.fee.type-placeholder',
                        })}
                        options={feeTypeOptions}
                        disabled={disabled}
                      />
                    </FeeFormInputContainer>

                    <FeeFormInputContainer>
                      <Input
                        label="staffingForm.fee.description"
                        name={`${FormFields.feeList}.${index}.${FeeFormFields.description}`}
                        placeholder={intl.formatMessage({
                          id: 'staffingForm.fee.description-placeholder',
                        })}
                        disabled={disabled}
                      />
                    </FeeFormInputContainer>

                    <FeeFormInputContainer small={true}>
                      <Input
                        label="staffingForm.fee.tva"
                        hints={
                          fee.type === FeeType.accomodation
                            ? [
                                intl.formatMessage({
                                  id: 'staffingForm.fee.tva-breakfast-warning',
                                }),
                              ]
                            : undefined
                        }
                        emphasized
                        disabled={!tvaFeeTypes.includes(fee.type) || disabled}
                        type="number"
                        name={`${FormFields.feeList}.${index}.${FeeFormFields.tva}`}
                        placeholder={intl.formatMessage({
                          id: 'staffingForm.fee.tva-placeholder',
                        })}
                        required={true}
                        step="any"
                      />
                    </FeeFormInputContainer>

                    <FeeFormInputContainer small={true}>
                      <Input
                        label="staffingForm.fee.total"
                        type="number"
                        name={`${FormFields.feeList}.${index}.${FeeFormFields.total}`}
                        placeholder={intl.formatMessage({
                          id: 'staffingForm.fee.total-placeholder',
                        })}
                        required={true}
                        step="any"
                        disabled={disabled}
                      />
                    </FeeFormInputContainer>

                    {distanceFeeTypes.includes(fee.type) && (
                      <FeeFormInputContainer>
                        <Input
                          label="staffingForm.fee.distance-km"
                          type="number"
                          name={`${FormFields.feeList}.${index}.${FeeFormFields.distanceKm}`}
                          placeholder={intl.formatMessage({
                            id: 'staffingForm.fee.distance-km-placeholder',
                          })}
                          required={true}
                          step="any"
                          disabled={disabled}
                        />
                      </FeeFormInputContainer>
                    )}

                    {volumeFeeTypes.includes(fee.type) && (
                      <FeeFormInputContainer>
                        <Input
                          label="staffingForm.fee.volume-l"
                          type="number"
                          name={`${FormFields.feeList}.${index}.${FeeFormFields.volumeL}`}
                          placeholder={intl.formatMessage({
                            id: 'staffingForm.fee.volume-l-placeholder',
                          })}
                          required={true}
                          step="any"
                          disabled={disabled}
                        />
                      </FeeFormInputContainer>
                    )}

                    {roundtripFeeTypes.includes(fee.type) && (
                      <FeeFormInputContainer>
                        <Input
                          label="staffingForm.fee.roundtrip"
                          type="checkbox"
                          name={`${FormFields.feeList}.${index}.${FeeFormFields.roundtrip}`}
                          checked={'roundtrip' in fee ? fee.roundtrip : false}
                          disabled={disabled}
                        />
                      </FeeFormInputContainer>
                    )}

                    {numberOfNightsFeeTypes.includes(fee.type) && (
                      <FeeFormInputContainer>
                        <Input
                          label="staffingForm.fee.number-of-nights"
                          type="number"
                          name={`${FormFields.feeList}.${index}.${FeeFormFields.numberOfNights}`}
                          placeholder={intl.formatMessage({
                            id: 'staffingForm.fee.number-of-nights-placeholder',
                          })}
                          required={true}
                          step="any"
                          disabled={disabled}
                        />
                      </FeeFormInputContainer>
                    )}
                  </FeeLineForm>
                </FeeLineFormContainer>
              ))}
            </>
          )}
          <AddFeeButton
            type="button"
            onClick={() => {
              arrayHelpers.push(emptyFeeForm);
              setShownFeeId(values.feeList.length);
            }}
            disabled={disabled}
          >
            {intl.formatMessage({
              id: 'staffingForm.fee.add',
            })}
          </AddFeeButton>
        </FeesFormContainer>
      )}
    />
  );
};

export default FeesForm;
