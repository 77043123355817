import React, { useEffect } from 'react';
import { FormikProps } from 'formik';
import Input from 'components/Input';
import { getError } from 'components/formUtils';
import RadioButtonGroup from 'components/RadioButtonGroup';
import RadioButton from 'components/RadioButton';
import { Project } from 'module/Project';
import { FormFields, InnerStaffingFormProps } from './StaffingForm.form';
import { StaffingFormValues } from '.';

export enum INTERVENTION_TYPE_INPUT_VALUES {
  PREPARATION = 'Préparation',
  ANIMATION = 'Animation',
}

type Props = { selectedProjet?: Project } & InnerStaffingFormProps &
  FormikProps<StaffingFormValues>;

const TrainingForm: React.FunctionComponent<Props> = ({
  errors,
  touched,
  intl,
  values,
  isEditionDisabled,
  selectedProjet,
  setFieldValue,
}) => {
  const shouldHidePreparation = (selectedProjet?.preparationDaysCount ?? 0) === 0;

  useEffect(() => {
    if (shouldHidePreparation) {
      setFieldValue(FormFields.interventionType, INTERVENTION_TYPE_INPUT_VALUES.ANIMATION);
    }
  }, [setFieldValue, shouldHidePreparation]);

  return (
    <>
      <Input
        name={FormFields.dayNumber}
        type="number"
        label="staffingForm.dayNumber"
        placeholder={intl.formatMessage({ id: 'staffingForm.dayNumber-placeholder' })}
        error={getError(!!touched.dayNumber, errors.dayNumber)}
        disabled={isEditionDisabled}
      />
      <RadioButtonGroup
        name={FormFields.interventionType}
        label="staffingForm.interventionType"
        error={getError(!!touched.interventionType, errors.interventionType)}
      >
        {Object.values(INTERVENTION_TYPE_INPUT_VALUES)
          .filter(
            value =>
              !(value === INTERVENTION_TYPE_INPUT_VALUES.PREPARATION && shouldHidePreparation),
          )
          .map(interventionTypeInputValue => (
            <RadioButton
              key={interventionTypeInputValue}
              id={'fee' + interventionTypeInputValue}
              name={FormFields.interventionType}
              value={interventionTypeInputValue}
              checked={values.interventionType === interventionTypeInputValue}
              disabled={isEditionDisabled}
            />
          ))}
      </RadioButtonGroup>
    </>
  );
};

export default TrainingForm;
