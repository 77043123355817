import { withFormik } from 'formik';
import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getModalPlanning } from 'module/Modal';
import { Planning, useGetPlanning, useUpdateOrCreatePlanningOnCondition } from 'module/Planning';
import { NEW_PLANNING_ID } from 'module/Planning/utils';
import { Fee, useCreateFees, useDeleteFees, useGetFeesByIds, useUpdateFees } from 'module/Fee';
import { getFeesByIds } from 'module/Fee/selector';
import { createNewFee } from 'module/Fee/utils';
import { Promise } from 'es6-promise';
import { handleSubmit, mapPropsToValues, mapValuesToPlanning, StaffingFormValues } from './service';
import InnerStaffingForm from './StaffingForm.form';

const StaffingFormFormContainer = withFormik({
  // The initial values can change because of the decision to stash the data in redux,
  // and read it before making sure it was updated
  enableReinitialize: true,
  mapPropsToValues,
  handleSubmit,
  // TODO fix
  // @ts-ignore-next-line
})(injectIntl(InnerStaffingForm));

const StaffingFormContainerWithUpdatePlanning: React.FunctionComponent<{ isReadonly: boolean }> = ({
  isReadonly,
}) => {
  const planning = useSelector(getModalPlanning);
  const [feesData, fetchFeesByIds] = useGetFeesByIds();
  const [getPlanningData, getPlanning] = useGetPlanning();
  const [createFeesData, createFees] = useCreateFees();
  const [updateFeesData, updateFees] = useUpdateFees();
  const [deleteFeesData, deleteFees] = useDeleteFees();
  const [updateOrCreatePlanningOnConditionLoading, updateOrCreatePlanningOnCondition] =
    useUpdateOrCreatePlanningOnCondition();

  const loading =
    feesData.loading ||
    getPlanningData.loading ||
    deleteFeesData.loading ||
    createFeesData.loading ||
    updateFeesData.loading ||
    updateOrCreatePlanningOnConditionLoading;

  const planningFeeIds = planning.feeList;

  useEffect(() => {
    if (planningFeeIds.length > 0) {
      fetchFeesByIds(planningFeeIds);
    }
  }, []);

  const fees: Array<Fee | undefined> = useSelector(getFeesByIds(planningFeeIds));
  // @ts-ignore-next-line
  const feesToDisplay: Fee[] = fees.filter(fee => fee !== undefined);

  const submitPlanning = async (values: StaffingFormValues): Promise<Planning[]> => {
    const updatedPlanning: Planning = {
      ...planning,
      ...mapValuesToPlanning(values),
    };
    return updateOrCreatePlanningOnCondition({
      existingPlanning: planning,
      conditionToUpdate: planning.id !== NEW_PLANNING_ID,
      planningToCreateOrUpdate: updatedPlanning,
    });
  };

  const emptyPromise = Promise.resolve([]);
  const submitFees = async (newFeeList: Fee[], linkedPlanning: Planning): Promise<any> => {
    const promisesArray = [];
    const planningFeeIdList: string[] = linkedPlanning.feeList;

    const feesToCreate = newFeeList.filter(fee => !planningFeeIdList.includes(fee.id));
    if (feesToCreate.length > 0) {
      const formattedFees = feesToCreate.map(rawfee => createNewFee(rawfee, linkedPlanning.id));
      promisesArray.push(createFees(formattedFees));
    } else {
      promisesArray.push(emptyPromise);
    }

    const feesToUpdate = newFeeList.filter(fee => planningFeeIdList.includes(fee.id));
    if (feesToUpdate.length > 0) {
      promisesArray.push(updateFees(feesToUpdate));
    } else {
      promisesArray.push(emptyPromise);
    }

    const feesToDelete = planningFeeIdList.filter(
      id => !newFeeList.map(fee => fee.id).includes(id),
    );
    if (feesToDelete.length > 0) {
      promisesArray.push(deleteFees(feesToDelete));
    } else {
      promisesArray.push(emptyPromise);
    }

    const promiseResults = await Promise.all(promisesArray);
    return promiseResults.flat().map(fee => fee.id);
  };

  const submit = async (values: StaffingFormValues) => {
    if (isReadonly) return;

    const plannings = await submitPlanning(values);

    // In the case of a merged cell update the submitPlanning method will return two plannings
    // In this case the morning planning is the second and last item of the array.
    const relatedPlanning = plannings && plannings.length > 1 ? plannings[1] : plannings[0];

    if (relatedPlanning) {
      await submitFees(values.feeList, relatedPlanning);
      getPlanning(relatedPlanning.id);
    }
  };

  return (
    <StaffingFormFormContainer
      key={planning.toString() + feesToDisplay.toString()}
      submit={submit}
      planning={planning}
      loading={loading}
      fees={feesToDisplay}
      isEditionDisabled={isReadonly}
    />
  );
};

export default StaffingFormContainerWithUpdatePlanning;
