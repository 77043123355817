import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { default as MaterialInput } from '@material-ui/core/Input';
import { peopleSelectors } from 'module/People';
import { getIsUserFreelance } from 'module/Login';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Select,
} from '@material-ui/core';
import Input from 'components/Input/Input';
import InputWithField from 'components/Input';
import RadioButton from 'components/RadioButton';
import { Formik } from 'formik';
import { isEmpty, isEqual } from 'lodash';

import {
  extractCustomPeopleSavedSelectionIndex,
  Filters,
  generateCustomSavedPeopleFilterType,
  getInvoicedFilterStatus,
  getPeopleFilterType,
  getValidationTimekeepingStatus,
  getValidCustomPeopleFilter,
  getValidCustomSavedSelections,
  getWorkAtHomeFilterStatus,
  isCustomSavedPeopleFilterType,
  PeopleFilterTypes,
  updateFilters,
  updateInvoiced,
  updateValidationTimekeeping,
  updateWorkAtHome,
  useAddCustomPeopleSavedSelection,
  useCurrentCustomPeopleSavedSelection,
  useDeleteCustomPeopleSavedSelection,
  useUpdateCustomPeopleSavedSelection,
} from 'module/Filter';

import { Label } from 'components/InputsUtils/WithRow';
import RangePicker from 'components/RangePicker';

import { getEndDate, getStartDate } from 'module/Filter';
import Button from 'components/Button';
import {
  ConfirmButton,
  CustomPeopleFilterContainer,
  CustomSelectionNameContainer,
  FilterContainer,
  ModalContainer,
  ModalContent,
  ModalHeader,
  RangePickerWrapper,
  StyledRadioButtonGroup,
} from './FilterModal.style';

type Filter = {
  name: Filters;
  label: string;
  updateCallback: () => void;
  active: boolean;
};

type FilterModalProps = {
  isModalOpen: boolean;
  onClose: () => void;
};

const FilterModal: React.FunctionComponent<FilterModalProps> = ({ isModalOpen, onClose }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const workAtHomeFilterStatus = useSelector(getWorkAtHomeFilterStatus);
  const validationTimekeepingStatus = useSelector(getValidationTimekeepingStatus);
  const invoicedStatus = useSelector(getInvoicedFilterStatus);
  const peopleFilterType = useSelector(getPeopleFilterType);
  const customPeopleFilter = useSelector(getValidCustomPeopleFilter);
  const customPeopleSavedSelections = useSelector(getValidCustomSavedSelections);
  const { currentCustomPeopleSavedSelection } = useCurrentCustomPeopleSavedSelection();
  const { addCustomPeopleSavedSelection } = useAddCustomPeopleSavedSelection();
  const { updateCustomPeopleSavedSelection } = useUpdateCustomPeopleSavedSelection();
  const { deleteCustomPeopleSavedSelection } = useDeleteCustomPeopleSavedSelection();

  const peopleList = useSelector(peopleSelectors.selectAll);
  const isUserFreelance = useSelector(getIsUserFreelance);

  const startDate = useSelector(getStartDate);
  const endDate = useSelector(getEndDate);

  const START_DATE = new Date(startDate);
  const END_DATE = new Date(endDate);

  const [currentStartDate, setStartDate] = useState(START_DATE);
  const [currentEndDate, setEndDate] = useState(END_DATE);

  const filters: Filter[] = [
    {
      name: Filters.workAtHome,
      label: intl.formatMessage({ id: 'filters.workAtHome' }),
      updateCallback: () => {
        dispatch(updateWorkAtHome(!workAtHomeFilterStatus));
        dispatch(updateInvoiced(false));
        dispatch(updateValidationTimekeeping(false));
      },
      active: workAtHomeFilterStatus,
    },
    {
      name: Filters.validationTimekeeping,
      label: intl.formatMessage({ id: 'filters.timeKeepingValidated' }),
      updateCallback: () => {
        dispatch(updateWorkAtHome(false));
        dispatch(updateInvoiced(false));
        dispatch(updateValidationTimekeeping(!validationTimekeepingStatus));
      },
      active: validationTimekeepingStatus,
    },
    {
      name: Filters.invoiced,
      label: intl.formatMessage({ id: 'filters.invoiced' }),
      updateCallback: () => {
        dispatch(updateWorkAtHome(false));
        dispatch(updateInvoiced(!invoicedStatus));
        dispatch(updateValidationTimekeeping(false));
      },
      active: invoicedStatus,
    },
  ];

  const handlePlanningUpdate = (values: {
    peopleFilterType: string;
    customPeopleFilter: string[];
    customPeopleSavedSelectionName: string;
  }) => {
    const newFilters: any = {};
    if (values.peopleFilterType !== peopleFilterType) {
      newFilters.peopleFilterType = values.peopleFilterType;
    }
    if (isCustomSavedPeopleFilterType(values.peopleFilterType)) {
      const indexToUpdate = extractCustomPeopleSavedSelectionIndex(values.peopleFilterType);
      updateCustomPeopleSavedSelection(Number(indexToUpdate), {
        name: values.customPeopleSavedSelectionName,
        people: values.customPeopleFilter,
      });
    } else if (
      values.peopleFilterType === PeopleFilterTypes.Custom &&
      values.customPeopleSavedSelectionName
    ) {
      const newIndex = addCustomPeopleSavedSelection({
        name: values.customPeopleSavedSelectionName,
        people: values.customPeopleFilter,
      });
      newFilters.peopleFilterType = generateCustomSavedPeopleFilterType(newIndex);
    }
    if (!isEqual(values.customPeopleFilter, customPeopleFilter)) {
      newFilters.customPeopleFilter = values.customPeopleFilter;
    }
    if (currentStartDate.getTime() !== START_DATE.getTime()) {
      newFilters.startDate = currentStartDate;
    }
    if (currentEndDate.getTime() !== END_DATE.getTime()) {
      newFilters.endDate = currentEndDate;
    }
    if (!isEmpty(newFilters)) dispatch(updateFilters(newFilters));
  };

  const isAllowedToSeePeopleFilters = peopleList.length !== 0 && !isUserFreelance;

  return (
    <Modal
      className="filterModal"
      open={isModalOpen}
      onClose={() => {
        onClose();
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ModalContainer>
        <ModalHeader>{intl.formatMessage({ id: 'filters.headerTitle' })}</ModalHeader>
        <ModalContent>
          <Formik
            initialValues={{
              [Filters.peopleFilterType]: peopleFilterType,
              customPeopleFilter,
              customPeopleSavedSelectionName: currentCustomPeopleSavedSelection?.name ?? '',
            }}
            validate={() => ({})}
            onSubmit={values => {
              handlePlanningUpdate(values);
              onClose();
            }}
          >
            {({ values, handleSubmit, setFieldValue }) => {
              const peopleSelectionTypeRadioButtons: {
                label: string;
                key: string;
                onChange?: () => void;
              }[] = [
                {
                  label: intl.formatMessage({ id: 'filters.only-me-label' }),
                  key: PeopleFilterTypes.MeOnly,
                },
                {
                  label: intl.formatMessage({ id: 'filters.everybody-label' }),
                  key: PeopleFilterTypes.Everybody,
                },
                ...customPeopleSavedSelections.map(({ name, people }, index) => ({
                  label: name,
                  key: generateCustomSavedPeopleFilterType(index),
                  onChange: () => {
                    setFieldValue('customPeopleFilter', people);
                    setFieldValue('customPeopleSavedSelectionName', name);
                  },
                })),
                {
                  label: intl.formatMessage({ id: 'filters.custom-label' }),
                  key: PeopleFilterTypes.Custom,
                  onChange: () => {
                    setFieldValue('customPeopleFilter', []);
                    setFieldValue('customPeopleSavedSelectionName', '');
                  },
                },
              ];

              const deleteCustomSavedSelection = () => {
                const indexToRemove = extractCustomPeopleSavedSelectionIndex(
                  values.peopleFilterType,
                );

                if (indexToRemove === undefined) return;

                deleteCustomPeopleSavedSelection(Number(indexToRemove));

                setFieldValue('peopleFilterType', PeopleFilterTypes.Custom);
                setFieldValue('customPeopleFilter', []);
                setFieldValue('customPeopleSavedSelectionName', '');
              };

              return (
                <form onSubmit={handleSubmit}>
                  {filters &&
                    filters.map(filter => (
                      <FilterContainer key={filter.name} onClick={() => filter.updateCallback()}>
                        <Input type="checkbox" name={filter.name} checked={filter.active} />
                        <div>{filter.label}</div>
                      </FilterContainer>
                    ))}
                  <RangePickerWrapper>
                    <Label>
                      <FormattedMessage id="filters.selected-period" />
                    </Label>
                    <RangePicker
                      selectedStartDate={currentStartDate}
                      selectedEndDate={currentEndDate}
                      startDateChangeHandler={(date: Date) => setStartDate(date)}
                      endDateChangeHandler={(date: Date) => setEndDate(date)}
                    />
                  </RangePickerWrapper>
                  {isAllowedToSeePeopleFilters && (
                    <StyledRadioButtonGroup
                      name={Filters.peopleFilterType}
                      label="filters.people-filter-label"
                      value={values.peopleFilterType}
                    >
                      {peopleSelectionTypeRadioButtons.map(radio => (
                        <RadioButton
                          key={radio.key}
                          id={radio.key}
                          checked={values.peopleFilterType === radio.key}
                          onChange={radio.onChange}
                          name={Filters.peopleFilterType}
                          value={radio.key}
                          label={radio.label}
                        />
                      ))}
                      {(values.peopleFilterType === PeopleFilterTypes.Custom ||
                        isCustomSavedPeopleFilterType(values.peopleFilterType)) && (
                        <CustomPeopleFilterContainer>
                          <FormControl>
                            <InputLabel id="custom-selection-label">
                              <FormattedMessage id="filters.custom-cubik-selection" />
                            </InputLabel>
                            <Select
                              labelId="custom-selection-label"
                              id="demo-mutiple-checkbox"
                              multiple
                              value={values.customPeopleFilter}
                              input={<MaterialInput />}
                              // @ts-ignore-next-line
                              renderValue={(selected: []) =>
                                `${selected.length} ${intl.formatMessage({
                                  id: 'filters.custom-cubik-selection-placeholder',
                                })}`
                              }
                            >
                              {peopleList.map(({ id, firstName, lastName }) => (
                                <MenuItem key={id} value={id} id={id}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        id={id}
                                        name="customPeopleFilter"
                                        value={id}
                                        checked={values.customPeopleFilter.includes(id)}
                                      />
                                    }
                                    label={`${firstName} ${lastName}`}
                                  />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <CustomSelectionNameContainer>
                            <FormattedMessage id="filters.custom-saved-selection-name-label" />
                            <InputWithField
                              name="customPeopleSavedSelectionName"
                              placeholder={intl.formatMessage({
                                id: 'filters.custom-saved-selection-name-placeholder',
                              })}
                            />
                          </CustomSelectionNameContainer>
                          {isCustomSavedPeopleFilterType(values.peopleFilterType) && (
                            <Button
                              onClick={e => {
                                e.preventDefault();
                                deleteCustomSavedSelection();
                              }}
                            >
                              <FormattedMessage id="filters.delete-custom-saved-selection" />
                            </Button>
                          )}
                        </CustomPeopleFilterContainer>
                      )}
                    </StyledRadioButtonGroup>
                  )}
                  <ConfirmButton type="submit">
                    {intl.formatMessage({ id: 'filters.updatePlanning' })}
                  </ConfirmButton>
                </form>
              );
            }}
          </Formik>
        </ModalContent>
      </ModalContainer>
    </Modal>
  );
};

export default FilterModal;
