import React, { useEffect } from 'react';
import { RootState } from 'redux/types';
import { IntlShape } from 'react-intl';
import Autocomplete from 'components/Autocomplete';
import Input from 'components/Input/Input';
import { Equipment, equipmentSelectors } from 'module/Equipment';
import { useGetEquipments } from 'module/Equipment/hooks';
import { useSelector } from 'react-redux';
import { FormFields } from './StaffingForm.form';
import { StaffingFormValues } from '.';

interface Props {
  intl: IntlShape;
  setFieldValue: Function;
  values: Record<FormFields, string | number | boolean | any[]> & StaffingFormValues;
  isEditionDisabled?: boolean;
}

const FormEquipment: React.FunctionComponent<Props> = ({
  intl,
  setFieldValue,
  values,
  isEditionDisabled,
}) => {
  const [getEquipmentData, getEquipment] = useGetEquipments();

  const equipments = useSelector(equipmentSelectors.selectAll);
  const selectedEquipmentId = values && values.specificMaterial;
  const selectedEquipment = useSelector((state: RootState) =>
    equipmentSelectors.selectById(state, selectedEquipmentId),
  );

  const SelectedEquipmentName = selectedEquipment
    ? selectedEquipment.name
    : values.specificMaterial;

  const getValueFromItem = (equipmentName: string) => {
    const equipmentValue = equipments.filter(
      (equipment: Equipment) => equipment.name === equipmentName,
    )[0];
    return equipmentValue ? equipmentValue.name : equipmentName;
  };

  useEffect(() => {
    Promise.all([getEquipment()]);
  }, []);

  return getEquipmentData.loading ? (
    <Input
      name={FormFields.specificMaterial}
      label="staffingForm.specificMaterial"
      placeholder={intl.formatMessage({ id: 'staffingForm.specificMaterial-placeholder' })}
      disabled={true}
    />
  ) : (
    <Autocomplete
      label="staffingForm.specificMaterial"
      placeholder={intl.formatMessage({ id: 'staffingForm.specificMaterial-placeholder' })}
      name="specificMaterial"
      items={equipments.filter(equipment => equipment.name !== undefined)}
      getItemValue={(equipment: Equipment) => equipment.name}
      value={SelectedEquipmentName}
      setFieldValue={setFieldValue}
      getValueFromItem={getValueFromItem}
      hints={[intl.formatMessage({ id: 'staffingForm.specificMaterial-hint' })]}
      inputProps={{ disabled: isEditionDisabled }}
    />
  );
};

export default FormEquipment;
