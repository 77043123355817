import styled from 'styled-components';

export const SnackBarsContainer = styled.div`
  position: fixed;
  z-index: 4;
  bottom: 20px;
  width: 100%;
  padding: 0 20px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
