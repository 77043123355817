import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEndDate, getStartDate, updateEndDate, updateStartDate } from 'module/Filter';
import RangePicker from 'components/RangePicker';

const ConnectedRangePicker: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  const startDate = useSelector(getStartDate);
  const endDate = useSelector(getEndDate);

  const START_DATE = new Date(startDate);
  const END_DATE = new Date(endDate);

  return (
    <RangePicker
      selectedStartDate={START_DATE}
      selectedEndDate={END_DATE}
      startDateChangeHandler={(date: Date) => dispatch(updateStartDate(date))}
      endDateChangeHandler={(date: Date) => dispatch(updateEndDate(date))}
    />
  );
};

export default ConnectedRangePicker;
