import styled from 'styled-components';
import EuroIcon from '@material-ui/icons/Euro';
import { getSpacing, size } from 'stylesheet';

export const ActionIcon = styled(EuroIcon)`
  color: white;
`;

export const ActionButton = styled.button`
  background: none;
  border: 0;
  cursor: pointer;
  display: none;
  margin: 0 0 0 ${getSpacing(5)};
  padding: ${getSpacing(1)};

  &:hover {
    opacity: 0.75;
  }

  &:disabled {
    opacity: 0.5;
  }

  @media (min-width: ${size.tablet}) {
    display: block;
  }
`;
