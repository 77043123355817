import styled from 'styled-components';
import { HEADER_HEIGHT, size } from 'stylesheet';

export const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
RootContainer.displayName = 'RootContainer';

export const PageContent = styled.main`
  flex-grow: 1;
  height: calc(100% - ${HEADER_HEIGHT.MOBILE});

  @media (min-width: ${size.tablet}) {
    height: calc(100% - ${HEADER_HEIGHT.DESKTOP});
  }
`;
PageContent.displayName = 'PageContent';
