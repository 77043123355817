import { Fee } from '.';

export const NEW_FEE_ID = '-1';

export const createNewFee = (rawFee: Omit<Fee, 'id' | 'planningId'>, planningId: string): Fee =>
  ({
    ...rawFee,
    id: NEW_FEE_ID,
    planningId,
  } as Fee);
