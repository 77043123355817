import React from 'react';
import { useIntl } from 'react-intl';
import { useToggleModal } from 'module/Modal';
import StaffingForm from 'components/StaffingForm';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/types';
import SaveIcon from '@material-ui/icons/Save';
import FabButton from 'components/FabButton';

import { peopleSelectors } from 'module/People';
import { getModalPlanning, getModalState } from 'module/Modal';
import { useIsPlanningEditionBlocked } from 'module/Planning';

// @ts-ignore
import close from 'assets/close.svg';
import {
  CloseLogo,
  FabButtonContainer,
  ModalBackground,
  ModalContainer,
  ModalHeader,
  ModalTitle,
  SaveButton,
  ScrollableContent,
} from './Modal.style';

const Modal: React.FunctionComponent = () => {
  const intl = useIntl();
  const toggleModal = useToggleModal();
  const hasModalChanged = useSelector((state: RootState) => state.modal.hasChanged);
  const planning = useSelector(getModalPlanning);
  const isReadonly = useIsPlanningEditionBlocked(planning);
  const formattedDate = intl.formatDate(new Date(planning.staffingDate)).replace(/\//g, '-');
  const isOpen = useSelector(getModalState);
  const title = useSelector((state: RootState) => {
    if (planning) {
      const people = peopleSelectors.selectById(state, planning.peopleId);
      if (people) {
        return `${people.firstName} ${people.lastName[0].toUpperCase()} - ${formattedDate} - ${
          planning.isAM
            ? intl.formatMessage({ id: 'modal.date.am' })
            : intl.formatMessage({ id: 'modal.date.pm' })
        }${planning.billed ? ` - ${intl.formatMessage({ id: 'modal.billed' })}` : ''}`;
      }
    }

    return '';
  });

  const submitForm = () => {
    // @ts-ignore-next-line
    document.getElementById('staffing-form-button').click();
  };
  const handleClose = () => {
    if (!hasModalChanged) {
      toggleModal();
      return;
    }
    if (window.confirm(intl.formatMessage({ id: 'modal.quitConfirmation' }))) {
      toggleModal();
    }
  };

  return (
    <ModalBackground isOpen={isOpen}>
      <ModalContainer isOpen={isOpen}>
        <ModalHeader>
          <CloseLogo onClick={() => handleClose()} src={close} alt="close" />
          <ModalTitle>{title}</ModalTitle>
          {!isReadonly && (
            <SaveButton onClick={submitForm}>
              {intl.formatMessage({ id: 'modal.update' })}
            </SaveButton>
          )}
        </ModalHeader>
        <>
          <ScrollableContent>
            {isOpen && (
              <StaffingForm
                key={
                  // DO NOT REMOVE
                  // this key ensure that the value of the planning from the store is updated in the StaffingForm component
                  title + isOpen.toString()
                }
                isReadonly={isReadonly}
              />
            )}
          </ScrollableContent>
          <FabButtonContainer>
            <FabButton Icon={SaveIcon} onClick={submitForm} disabled={isReadonly} />
          </FabButtonContainer>
        </>
      </ModalContainer>
    </ModalBackground>
  );
};

export default Modal;
