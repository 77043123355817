import styled from 'styled-components';
import { colorPalette, getSpacing, size, typography } from 'stylesheet';

export const FilterButtonContainer = styled.div`
  ${typography.h1}
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: ${getSpacing(1)};
  cursor: pointer;

  &:hover {
    opacity: 0.4;
  }

  @media (min-width: ${size.tablet}) {
    background-color: ${colorPalette.white};
    padding: ${getSpacing(2)};
  }
`;

FilterButtonContainer.displayName = 'FilterButtonContainer';

export const FilterIconContainer = styled.div`
  display: flex;
  height: ${getSpacing(5)};
  fill: ${colorPalette.white};

  @media (min-width: ${size.tablet}) {
    fill: ${colorPalette.cubikBlue};
  }
`;

FilterIconContainer.displayName = 'FilterIconContainer';

export const FilterLabel = styled.div`
  font-size: ${getSpacing(3)};
  color: ${colorPalette.cubikBlue};
  display: none;
  margin-left: ${getSpacing(2)};

  @media (min-width: ${size.tablet}) {
    display: flex;
  }
`;

FilterLabel.displayName = 'FilterLabel';
