import React from 'react';
import RadioButtonGroup from 'components/RadioButtonGroup';
import RadioButton from 'components/RadioButton';
import { getError } from 'components/formUtils';
import { FormikProps } from 'formik';
import { useSelector } from 'react-redux';
import { getIsPeopleFromTeam, Teams } from 'module/Teams';
import { getModalPlanning } from 'module/Modal';
import { PROJECT_TYPES, projectSelectors } from 'module/Project';
import { RootState } from 'redux/types';
import { StaffingFormValues } from '.';
import { FormFields, InnerStaffingFormProps } from './StaffingForm.form';

export enum INVOICED_INPUT_VALUES {
  YES = 'Oui',
  NO = 'Non',
  COMPANIONSHIP = 'Compagnonnage (non facturé)',
  INVOICED_COMPANIONSHIP = 'Compagnonnage (facturé)',
}

export enum INVOICED_FREELANCE_INPUT_VALUES {
  YES = 'Oui',
  NO = 'Non',
}

const InvoicingForm: React.FunctionComponent<
  InnerStaffingFormProps & FormikProps<StaffingFormValues>
> = ({ errors, touched, values, isEditionDisabled }) => {
  const planning = useSelector(getModalPlanning);
  const isFreelance = useSelector(getIsPeopleFromTeam(planning.peopleId, Teams.FREELANCE));

  const selectedProjectId = values && values.project;
  const selectedProject = useSelector((state: RootState) =>
    projectSelectors.selectById(state, selectedProjectId),
  );
  const isInternal = !!selectedProject && selectedProject.type === PROJECT_TYPES.INTERNAL;

  return !isInternal ? (
    <RadioButtonGroup
      name={FormFields.invoiced}
      label="staffingForm.invoiced"
      error={getError(!!touched.invoiced, errors.invoiced)}
    >
      {Object.values(INVOICED_INPUT_VALUES)
        .filter(
          invoicedInputValue =>
            invoicedInputValue !== INVOICED_INPUT_VALUES.INVOICED_COMPANIONSHIP || isFreelance,
        )
        .map(invoicedInputValue => (
          <RadioButton
            key={invoicedInputValue}
            id={invoicedInputValue}
            checked={values.invoiced === invoicedInputValue}
            name={FormFields.invoiced}
            value={invoicedInputValue}
            disabled={isEditionDisabled}
          />
        ))}
    </RadioButtonGroup>
  ) : isFreelance ? (
    <RadioButtonGroup
      name={FormFields.invoicedFreelance}
      label="staffingForm.invoicedFreelance"
      error={getError(!!touched.invoicedFreelance, errors.invoicedFreelance)}
    >
      {Object.values(INVOICED_FREELANCE_INPUT_VALUES).map(invoicedInputValue => (
        <RadioButton
          key={invoicedInputValue}
          id={invoicedInputValue}
          checked={values.invoicedFreelance === invoicedInputValue}
          name={FormFields.invoicedFreelance}
          value={invoicedInputValue}
          disabled={isEditionDisabled}
        />
      ))}
    </RadioButtonGroup>
  ) : null;
};

export default InvoicingForm;
