import React from 'react';
import { useDismissSnackBar, useSnackBars } from 'module/SnackBars';
import SnackBar from './SnackBar';
import { SnackBarsContainer } from './SnackBars.style';

const SnackBars = () => {
  const snackbars = useSnackBars();
  const dismiss = useDismissSnackBar();
  return (
    <SnackBarsContainer>
      {snackbars.map(({ id, type, text }) => (
        <SnackBar key={id} type={type} text={text} onDismiss={() => dismiss(id)} />
      ))}
    </SnackBarsContainer>
  );
};

export default SnackBars;
