import React from 'react';
import { useField } from 'formik';
import { WrappedComponentWithFieldProps, WrappedInputProps } from './types';

const withField = <T,>(
  WrappedComponent: React.FunctionComponent<WrappedComponentWithFieldProps<T>>,
): React.FunctionComponent<WrappedInputProps> => {
  const WrappedComponentWithRow = (props: WrappedInputProps) => {
    const [field, meta] = useField(props.name);
    return <WrappedComponent {...props} {...field} {...meta} />;
  };

  return WrappedComponentWithRow;
};

export default withField;
